import { useState } from "react";
import { useFetch } from "./useFetch";
import { STICKY_CLOSED_KEY, STICKY_SHOW_KEY, USER_HAS_PLAN_KEY } from "../assets/constants";
import { useCookies } from 'react-cookie';
// Hook para gestión de planes a usuarios
export const useUserPlan = () => {
    const [data, handleFetch] = useFetch();
    const [hasPlan, setHasPlan] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies([USER_HAS_PLAN_KEY]);

    // Handle para guardar cookies de usuario
    const saveUserHasPlan = (userPlan = hasPlan) => {
        setCookie(USER_HAS_PLAN_KEY, userPlan);
    }
    // Handle para eliminar cookies de usuario
    const deleteUserHasPlan = () => {
        removeCookie(USER_HAS_PLAN_KEY);
    }
    // Remover cookies de stickyBanner
    const deleteStickyBanner = () => {
        sessionStorage.removeItem(STICKY_SHOW_KEY);
        sessionStorage.removeItem(STICKY_CLOSED_KEY);
    }

    const resolver = (resolve, nv) => (
        (pv) => {
            resolve(nv);
            setCookie(USER_HAS_PLAN_KEY, nv);
            return nv;
        });
    const checkUserPlan =  (accessToken = null) => {
        return new Promise(resolve => {
            if(!!!accessToken) {
                setHasPlan(resolver(resolve, false));
                deleteUserHasPlan();
            } else {
                let savedUserHasPlan = (!!cookies[USER_HAS_PLAN_KEY])?cookies[USER_HAS_PLAN_KEY]:undefined;
                if (typeof savedUserHasPlan=='string') savedUserHasPlan=JSON.parse(savedUserHasPlan);
                if(!!!savedUserHasPlan) {
                    handleFetch({
                        url: '/users/plans/current',
                        method: 'POST',
                        headers: {Authorization: `Bearer ${accessToken}`}
                    })
                    .then ((p)=> 
                        {setHasPlan(resolver(resolve,p))})
                    .catch(()=>
                        setHasPlan(resolver(resolve, false)))
                } else {
                    setHasPlan(resolver(resolve,savedUserHasPlan));
                }
            }
        })
    }


    return {checkUserPlan,deleteUserHasPlan,saveUserHasPlan,hasPlan,deleteStickyBanner};
}