import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import { Filters } from '../filters/Filters';
import { LineChartComponent } from '../charts/LineChart';
import { TinyBarChart } from '../charts/TinyBarChart';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { apiConfig } from '../../../api/apiConfig';
import { SimplePieChart } from '../charts/PieRecharts';
import { DonutChart } from '../charts/DonutChart';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2024<br>
 * DashboardReports sección administrativa
 * @interface DashboardReportsAdminSection
 */
export const DashboardReportsAdminSection = () => {

    const [totalUsers, setTotalUsers] = useState(0);
    const [segment, setSegment] = useState([]);
    const [totalPlans, setTotalPlans] = useState(0);
    const [totalCourses, setTotalCourses] = useState(0);
    const [topInitiatedUsers, setTopInitiatedUsers] = useState([]);
    const [topFinalizedUsers, setTopFinalizedUsers] = useState([]);
    const [topCoursesCompleted, setTopCoursesCompleted] = useState([]);
    const [topCoursesMostEnrolled, setTopCoursesMostEnrolled] = useState([]);
    const [data, handleFetch] = useFetch();
    const [dataFilter, setDataFilter] = useState({
        companyId: '',
        segmentId: '',
        until: '',
        since: '',
    })
    const location = useLocation();
    const { pathname } = location;
    const refreshSegments = () => {
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsesegment = handleFetch({
            url: `bo/segment?&companyId=${apiConfig?.company}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsesegment
            .then((r) => {
                setSegment(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshData = () => {        
        let filtertype = dataFilter.segmentId !== '' ? "&segmentId=" + dataFilter.segmentId : ''
        let filterendDate = dataFilter.until !== '' ? "&endDate=" + dataFilter.until : ''
        let filterstartDate  = dataFilter.since !== '' ? "&startDate=" + dataFilter.since : ''
        let filtercompany = "&company=" +apiConfig.company
        const responsec = handleFetch({
            url: `bo/datadashboardreports?${filtercompany}${filtertype}${filterstartDate}${filterendDate}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
            responsec.then(async (r) => {
                setTopInitiatedUsers(r?.topInitiatedUsers)
                setTopFinalizedUsers(r?.topFinalizedUsers)
                setTopCoursesCompleted(r?.topCoursesCompleted)
                setTopCoursesMostEnrolled(r?.topCoursesMostEnrolled)
                setTotalUsers(r?.totalUsers)
                setTotalCourses(r?.totalCourses)
                setTotalPlans(r?.totalPlans)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        window.history.replaceState(null, '');
        refreshData();
        refreshSegments();
    }, [totalPlans])

    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        refreshData();
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    const fecha = new Date();
    const diaSemana = fecha.toLocaleDateString(undefined, { weekday: 'long' });
    const dia = fecha.getDate();
    const mes = fecha.toLocaleDateString(undefined, { month: 'long' });
    const horaAmPm = fecha.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: true });
    const [hora, amPm] = horaAmPm.split(' ');
    //const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))
    const resetState = () => {
        //setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index) => {
        /*let auxArray = Array(users.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)*/
    }
    return (
        <>
            <div className='row justify-content-center mt-4 mb-4'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Reportes en Dashboard</h1>
                        </div>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className='LandingHeader__text-and-border'>
                                <Link to={'../admin/dashboard'} className={`${pathname == '/admin/dashboard' ? 'LandingHeader__text-black-active' : 'LandingHeader__text-color'}`}>Gráficas</Link>
                            </div>
                            <div className='LandingHeader__text-no-border'>
                                <Link to={'../admin/dashboardreport'} className={`${pathname == '/admin/dashboardreport' ? 'LandingHeader__text-black-active' : 'LandingHeader__text-color'}`}>Reportes</Link>
                            </div>
                        </div>
                    </div>


                    <div className="row d-flex justify-content-end ">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                  <div className="col-4 d-flex align-items-center bg-green justify-content-center rounded-left">
                    <div className="text-center">
                      <div
                        format="MMMM"
                        className="overflowm text-uppercase text-sm"
                        style={{display: "inline-block"}}
                      >
                        {mes}
                      </div>
                      <br />
                      <div
                        format="D"
                        className="text-uppercase h2report mt0"
                        style={{display: "inline-block"}}
                      >
                        {dia}
                      </div>
                    </div>
                  </div>
                  <div className="col-8 p-3 rounded-right">
                    <div
                      format="dddd"
                      className="text-uppercase"
                      style={{display: "inline-block"}}
                    >
                      {diaSemana}
                    </div>
                    <br />
                    <div
                      format="h:mm"
                      className="h2hour mt0 mr-sm"
                      style={{display: "inline-block"}}
                    >
                      {hora}
                    </div>
                    <div
                      className="text-muted text-sm"
                      style={{position: "absolute", bottom: "5%"}}
                    >
                      {amPm}
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                    <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
                        <i className="icons fa-solid fa-user"></i>
                    </div>
                    <div className="col-8 p-3 bg-primary rounded-right">
                        <div className="h2report mt-0">{totalUsers} Usuarios</div>
                        <div className="overflowr text-uppercase">Usuarios Registrados</div>
                    </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                    <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left">
                        <i className="icons fa-solid fa-notes-medical"></i>
                    </div>
                    <div className="col-8 p-3 bg-purple rounded-right">
                        <div className="h2report mt-0">{totalPlans}</div>
                        <div className="overflowr text-uppercase">Planes agregados</div>
                    </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <div className="cardReport flex-row align-items-center align-items-stretch border-0">
                    <div className="col-4 d-flex align-items-center bg-green-dark justify-content-center rounded-left">
                        <i className="icons fa-solid fa-file-contract"></i>
                    </div>
                    <div className="col-8 p-3 bg-green rounded-right">
                        <div className="h2report mt-0">{totalCourses} Cursos</div>
                        <div className="overflowr text-uppercase">Cursos Realizados</div>
                    </div>
                    </div>
                </div>
                
            </div>

            
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            onSubmit={onSubmit}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Inicio</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={dataFilter?.until || newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Fin</span>
                                <div>
                                    <input type="date" name="until" value={dataFilter?.until || ''} onChange={handleChangeFilter} min={dataFilter?.since} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Tipo de usuario</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='segmentId'
                                        value={dataFilter?.segmentId || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="">Seleccione una segmentación</option>
                                        {
                                            segment.length !== 0 &&
                                            segment.map((data, index)=>{
                                                    return(
                                                        <option value={data?.type} key={data?.id}>{data?.name}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                        <><div className='row text-center justify-content-center'>
                                            <div className='col-12 col-lg-6 mb-4'>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        USUARIOS MAS CURSOS INICIADOS
                                                    </div>
                                                </div>
                                                <table className='UserAdmin__table' >
                                                    <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                                                        <tr style={{ cursor: "pointer" }}>
                                                            <th className="overflow" style={{ width: "40%", paddingLeft: "16px", textAlign: "left" }}>Nombre</th>
                                                            <th className="overflow" style={{ width: "40%", paddingLeft: "16px", textAlign: "left" }}>Documento</th>
                                                            <th className="overflow" style={{ width: "20%", paddingLeft: "16px", textAlign: "left" }}>Cursos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {   topInitiatedUsers?.length != 0 ?
                                                            topInitiatedUsers?.slice(0, 10).map((user, index) => {
                                                                    return (
                                                                        <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                            <td className="overflow" style={{ textAlign: "left", paddingLeft: "16px" }}>{user?.name} {user?.lastname}</td>
                                                                            <td className="overflow" style={{ textAlign: "left", paddingLeft: "16px" }}>{user?.documentType} {user?.document}</td>
                                                                            <td className="overflow" style={{ textAlign: "center" }}>{user?.value}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan="3" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='col-12 col-lg-6 mb-4'>
                                                <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        USUARIOS MAS CURSOS FINALIZADOS
                                                    </div>
                                                </div>
                                                <table className='UserAdmin__table' >
                                                    <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                                                        <tr style={{ cursor: "pointer" }}>
                                                            <th className="overflow" style={{ width: "40%", paddingLeft: "16px", textAlign: "left" }}>Nombre</th>
                                                            <th className="overflow" style={{ width: "40%", paddingLeft: "16px", textAlign: "left" }}>Documento</th>
                                                            <th className="overflow" style={{ width: "20%", paddingLeft: "16px", textAlign: "left" }}>Cursos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            topFinalizedUsers?.length != 0 ?
                                                            topFinalizedUsers?.slice(0, 10).map((user, index) => {
                                                                    return (
                                                                        <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                            <td className="overflow" style={{ textAlign: "left", paddingLeft: "16px" }}>{user?.name} {user?.lastname}</td>
                                                                            <td className="overflow" style={{ textAlign: "left", paddingLeft: "16px" }}>{user?.documentType} {user?.document}</td>
                                                                            <td className="overflow" style={{ textAlign: "center" }}>{user?.value}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan="3" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>                                                
                                            </div>
                                        </div>
                                        <div className='row text-center justify-content-center'>
                                            <div className='col-12 mb-4'>
                                            <div className='row text-center justify-content-center'>
                                                    <div className='mt-4 mb-2 fw-bold'>
                                                        TOP CURSOS MAS INSCRITOS
                                                    </div>
                                                </div>
                                                <table className='UserAdmin__table' >
                                                    <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                                                        <tr style={{ cursor: "pointer" }}>
                                                            <th className="overflow" style={{ width: "75%", paddingLeft: "16px", textAlign: "left" }}>Nombre del curso</th>
                                                            <th className="overflow" style={{ width: "25%", paddingLeft: "16px", textAlign: "center" }}>Cursos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            topCoursesMostEnrolled?.length != 0 ?
                                                            topCoursesMostEnrolled?.map((course, index) => {
                                                                    return (
                                                                        <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                                                            <td className="overflow" style={{ textAlign: "left", paddingLeft: "16px" }}>{course?.name}</td>
                                                                            <td className="overflow" style={{ textAlign: "center" }}>{course?.value}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan="3" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>  
                                            </div>
                                        </div>
                                        </>
                                    
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}