import React, {useEffect, useState} from 'react';
import { calculatePastDays, getPlanStatus } from '../../controls/UIControls';
import { useFetch } from '../../../hooks/useFetch';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { Link } from 'react-router-dom';
import moment from 'moment';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página contenido de sección usuarios
 * @interface UserCreateEditSection
 */
export const UserCreateEditSection = ({user, segment}) => {
    console.log("user",user)
    console.log("segment",segment)
    const [logSession, setLogSession] = useState([]);
    const [totalSession, setTotalSession] = useState([]);
    const [courses, setCourses] = useState([]);
    const { authToken }= useAuthToken(true);
    const [fetchData, handleFetch] = useFetch();
    const refreshLogsLogins = () => {
        // consulta de endpoint para historico de inicios de sesión realizados por el usuario, se pasa por parámetro id del usuario
        const responseph = handleFetch({
            url:`bo/viewlogs?userId=${user.id}&action=login&$limit=5`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responseph
            .then((r) => {
                    setLogSession(r.data)
                    setTotalSession(r.total)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {
                    }
                })
    }
    // consulta de endpoint de planes para obtener información de plan individual, se recibe parametro llamado planID
    useEffect(() => {
        refreshLogsLogins();
        // consulta de endpoint para historico de cursos inscritos por el usuario, se pasa por parámetro id del usuario
        const responsech = handleFetch({
            url:`bo/reportscourses?userId=${user.id}&$limit=500`,
            method: 'GET',
            headers: {Authorization: `Bearer ${authToken}`}
        });
        responsech
            .then((r) => {
                    setCourses(r.data)
                },
                error => {
                    console.log(error.errors);
                    if(error.errors) {
                    }
                })
    }, [])
    // Header para historial de cursos
    const headercourses = [
        {
            "name":"FECHA INICIO",
            "width": "20%"
        },
        {
            "name":"CURSOS",
            "width": "40%"
        },
        {
            "name": "PROGRESO",
            "width":"20%"
        },
        {
            "name": "CERTIFICADO",
            "width":"20%"
        }
    ];
    // Tipos de documentos
    const typeDocument = [
        {
            "id":"CC",
            "name":"Cédula de Ciudadanía"
        },
        {
            "id":"CE",
            "name":"Cédula de Extranjería"
        },
        {
            "id":"DIE",
            "name":"Documento de Identidad Extranjera"
        },
        {
            "id":"PA",
            "name":"Pasaporte"
        },
        {
            "id":"TI",
            "name":"Tarjeta de Identidad"
        },
        {
            "id":"PEP",
            "name":"Permiso Especial de Permanencia"
        }
    ];
    const diasPasados = calculatePastDays(logSession[0]?.createdAt);
    const typeDocumentUser = typeDocument.find(
        (item) => item.id === user?.documentType
    );
    return (
        <>
            <Link to='/admin/usuarios' className='px-4 px-sm-5 text_primary fw-bold'>Volver a usuarios</Link>
            <div className='text-center'><h1>Perfil de usuario </h1> </div>
            <div className='d-flex justify-content-center mb-4'>
                <div className='UserFormContainer row m-2 p-3 col-10 col-md-10'>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Nombre</label>
                                    <label className="form-label UserFormContainer__sublabel">{user?.name}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Apellido</label>
                                    <label className="form-label UserFormContainer__sublabel">{user?.lastname}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Correo electrónico</label>
                                    <label className="form-label UserFormContainer__sublabel">{user?.email}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Fecha de registro</label>
                                    <label className="form-label UserFormContainer__sublabel">{moment.utc(user?.createdAt).format('DD/MM/YYYY')}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Segmentación asignada</label>
                                    <label className="form-label UserFormContainer__sublabel">{segment.name}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Último inicio de sesión</label>
                                    <label className="form-label UserFormContainer__sublabel">{user.lastLogin ? moment.utc(user?.lastLogin).format('DD/MM/YYYY') : 'No ha iniciado sesión por primera vez'}</label>
                                </div>
                            </div>
                        </div>

                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Tipo de documento</label>
                                    <label className="form-label UserFormContainer__sublabel">{typeDocumentUser?.name}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Número de documento</label>
                                    <label className="form-label UserFormContainer__sublabel">{user?.document}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Teléfono</label>
                                    <label className="form-label UserFormContainer__sublabel">{user?.phone}</label>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Dirección</label>
                                    <label className="form-label UserFormContainer__sublabel">{user?.address}</label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Cantidad de inicios de sesión</label>
                                    <label className="form-label UserFormContainer__sublabel">{totalSession} veces</label>
                                 </div>
                                 <div className="col-12 col-md-6">
                                    <label className="form-label UserFormContainer__label">Cantidad de inicios de sesión</label>
                                    <label className="form-label UserFormContainer__sublabel">{diasPasados !== null && (
                                            <p>
                                            Han pasado {diasPasados} {diasPasados === 1 ? 'día' : 'días'} desde la fecha seleccionada.
                                            </p>
                                        )}</label>
                                </div>
                            </div>
                        </div>
                        { user?.verifyShortToken &&
                             <div className='mb-3'>
                             <div className='row'>
                                 <div className="col-12 col-md-6">
                                     <label className="form-label UserFormContainer__label">Código de confirmación</label>
                                     <label className="form-label UserFormContainer__sublabel">{user?.verifyShortToken}</label>
                                 </div>
                                 <div className="col-12 col-md-6"></div>
                             </div>
                         </div>
                        }
                        <div className='' style={{width: "100%", marginTop: "17px", overflowX: "auto"}}>
                        <div className='mb-3'>
                            <label className="form-label PlanFormContainer__label">Cursos inscritos</label>
                            <table className='UserAdmin__table' >
                                <thead className='UserAdmin__subtable-header' style={{height: "52px"}}>
                                <tr style={{cursor: "pointer"}}>
                                    {headercourses.map((header, index)=>{
                                        return(
                                            <th key={index} style={{width: header.width, paddingLeft: "16px", textAlign: "center"}}>{header.name}</th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    courses.length !== 0 ?
                                        courses.map((data, index)=>{
                                            return(
                                                <tr className={`UserAdmin__table-row ${index%2 ===0 ? "UserAdmin__table-pair-row": "UserAdmin__table-odd-row" }`} key={index}>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{moment.utc(data?.['createdAt']).format('DD/MM/YYYY')}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data?.['course.title']}</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "center"}}>{data?.progress}%</td>
                                                    <td style={{paddingLeft: "16px", textAlign: "-webkit-center"}}>{data?.['users_studies_certification.certificationUrl'] ?
                                                        <a href={data?.['users_studies_certification.certificationUrl']}>
                                                            <i className='fa-solid fa-download UserAdmin__color-primary'></i>
                                                        </a> :'NO DISPONIBLE'}</td>
                                                </tr>
                                            );
                                        })
                                        :   <tr>
                                            <td colSpan="7" style={{padding: "16px", textAlign: "center"}}><strong>No existen registros</strong></td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        </div>
                        
                </div>
            </div>
        </>
    );
}