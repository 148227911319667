import React from 'react'
import '../../styles/components/coming-soon/coming-soon.scss'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página proximamente
 * @interface ComingSoonPage
 */
export const ComingSoonPage =  () => {
    const landingImages = require.context('../../assets/images', true);
    return (<>
        <div className={`auth__main min-vh-100 d-flex align-items-center`}>
            <div className={`ComingSoon__box-container text-center `}>
                <img
                    alt='Ean logo'
                    src={landingImages('./placeholder-logo.png')}
                    className="mx-auto d-block"
                />
                <i className="fa-regular fa-clock fa-4x ComingSoon__icon my-3"></i>
                <div className='ComingSoon__title' >PRÓXIMAMENTE</div>
                <div className='ComingSoon__text'>podrás acceder a la Educación sin Barreras</div>
                {/** 
                <div className='ComingSoon__social-icons'>
                <a href='#'><i className="fa-brands fa-linkedin-in"></i></a>
                <a href='#'><i className="fa-brands fa-twitter"></i></a>
                <a href='#'><i className="fa-brands fa-facebook"></i></a>
                </div>
                */}
            </div>
        </div>
            
</>);
}
