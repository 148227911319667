import React, { useState, useEffect } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthFormContainerPage, SubmitFormButton } from '../controls/UIControls';
import * as CONSTANTS from '../../assets/constants';
import { useGetPlan } from '../../hooks/useGetPlan';
import { AmountFormat } from '../controls/AmountFormat';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Interface para tipo de pago
 * @interface PaymentTypePage
 */
export const PaymentTypePage = () => {

    const [showAlert, setShowAlert] = useState(false);
    const [planAssociated, setPlanAssociated] = useState(true);
    const [planAssociatedMessage, setPlanAssociatedMessage] = useState('');
    const [getPlanStarted, setGetPlanStarted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const location = useLocation();
    const  [searchParams] = useSearchParams();
    const {getPlan, associatePlan, mpPreference} = useGetPlan();

    const PLAN_ASSOCIATED = 'El plan fue asociado correctamente a tu cuenta.';
    const PLAN_NOT_ASSOCIATED = 'Ocurrió un error al asociar el plan seleccionado a tu cuenta.';
    const PLAN_PURCHASED = 'La compra de tu plan se realizó con éxito.';
    const PLAN_NOT_PURCHASED = 'El pago de tu plan fue rechazado.';

    //const planAssociated = location.state?.planAssociated
    const [buttonProperties, setButtonProperties] = useState({label:'Ir al login', linkProps: {to:'/ingreso'}});


    const handleGetPlanResponse = status => {
        setGetPlanStarted(true);
        switch(status) {
            case 'assigned':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'not_assigned':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'purchase':
                setShowAlert(false);
                break;
            case 'approved':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_PURCHASED);
                setShowAlert(true);
            case 'rejected':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_PURCHASED);
                setShowAlert(true);
        }
    };

    const checkPurchaseResponse = () => {
        if(!getPlanStarted) {
            const status = searchParams.get('status');
            const preference = searchParams.get('preference_id');
            const userPlanData = searchParams.get('external_reference');
            const paymentId = searchParams.get('payment_id');
            const merchandOrderId = searchParams.get('merchant_order_id');

            if( [status, preference, userPlanData, paymentId, merchandOrderId].every(p => p!==null))
                setGetPlanStarted(true);
        }
    }

    const processAnswer = () => {
        const status = searchParams.get('status');
        const userPlanData = searchParams.get('external_reference');
        const paymentId = searchParams.get('payment_id');
        const merchandOrderId = searchParams.get('merchant_order_id');
        if(status=='approved') {
            const userPlan = userPlanData.split('-');
            associatePlan({id: parseInt(userPlan[0])}, {id: parseInt(userPlan[1])},`${paymentId}-${merchandOrderId}` )
                .then (handleGetPlanResponse);
        } else {
            handleGetPlanResponse(status);
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        const {user,plan} = location.state;
        setProcessing(true);

        getPlan(user,plan,false)
            .then(handleGetPlanResponse)
            .finally(()=>{setProcessing(false);});
    }


    useEffect(()=> {
        if(!getPlanStarted) {
            setButtonProperties({label:'Continuar...', linkProps: {to:'/', onClick: handleClick}});
        } else {
            processAnswer();
            setButtonProperties({label:'Ir al login', linkProps: {to:'/ingreso'}})
        }
    },[getPlanStarted, mpPreference]);

    const [data, handleFetch] = useFetch();
    const plan = location.state.plan;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { register, handleSubmit, watch, setValue,
        control, formState: { errors }, setError,
        getValues, setFocus } = hookUseForm();
    const navigator = useNavigate();
    const registerWithPaymentForm = (
        <form onSubmit={ handleClick }>

            <div className='row justify-content-center'>
                <div className='col-12 col-sm-6 mt-3'>
                    <div style={{textAlign: "center"}}>
                        <label style={{marginBottom: "5px", fontSize: "15px"}}>Tu pago se hará mediante:</label>
                        <img
                            alt='Mercado Pago'
                            src={landingImages('./mercado-pago-logo.png')}
                            style={{width: "160px", height: "auto"}}
                            className="rounded mx-auto d-block"
                        />
                    </div>

                </div>

                <div className='col-12 col-sm-6 mt-3'>

                    <div className='PaymentType__table-container mt-4 mb-3'>
                        <table className='PaymentType__table'>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>{plan.name}</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='price'
                                    value={plan.price}
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>Descuento</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='discount'
                                    value='0'
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                            <tr className='PaymentType__tr'>
                                <td className='PaymentType__td-left'>Total</td>
                                <td className='PaymentType__td-right'><AmountFormat
                                    name='total'
                                    value={plan.price}
                                    displayType='text'
                                    currency='COP'
                                /></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <SubmitFormButton main_title="Ir a pagar" wait_title="Procesando..." loading={data.loading}></SubmitFormButton>
            </div>
        </form>

    );
    return (
        <AuthFormContainerPage title="Confirmar pago" content={registerWithPaymentForm} containerSubClass='full-width' registerPay={3}></AuthFormContainerPage>
    )
}