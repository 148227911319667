import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  title: "Cursos mas vistos",
  is3D: true,
  backgroundColor: "transparent"
};
export function GooglePieChart({data}) {
  const tData = data && data.length !==0 && [
    ["Curso", "Valor"],
    ...data.map(item => [item.name, item.value])
  ];
  return (
    tData && <Chart
      chartType="PieChart"
      data={tData}
      options={options}
      width={"100%"}
      height={"400px"}
    />)
}
