import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LandingPage } from '../components/landing/LandingPage'
import { VerifyEmailPage } from '../components/register/VerifyEmailPage'
import { AccountCreatedPage } from '../components/register/AccountCreatedPage'
import { ProfilePage } from '../components/profile/ProfilePage'
import { UserContext } from '../context/UserContext'
import { LoginPage } from '../components/login/LoginPage'
import { PasswordRecoveryPage } from '../components/password-recovery/PasswordRecoveryPage'
import { PasswordMessageSentPage } from '../components/password-recovery/PasswordMessageSentPage'
import { SuccessfulKeyChangePage } from '../components/change-password/SuccessfulKeyChangePage'
import { ResetPasswordPage } from '../components/reset-password/ResetPasswordPage'
import { CatalogPage } from '../components/catalog/CatalogPage'
import { CourseDetailPage } from '../components/course-detail/CourseDetailPage'
import { ContactUsPage } from '../components/contact-us/ContactUsPage'
import { NotFoundPage } from '../components/not-found/NotFoundPage'
import { IdVerificationPage } from '../components/id-verification/IdVerification'
import { UpdateData } from '../components/id-verification/UpdateData'
import { PaymentTypePage } from '../components/register/PaymentTypePage'
import { SelectRegister } from '../components/register/SelectRegister'
import { DirectRegister } from '../components/register/DirectRegisterPage'
import { ComingSoonPage } from '../components/coming-soon/ComingSoonPage'
import { PlanPage } from '../components/plan/PlanPage'
import { DashboardAdminPage } from '../components/admin/dashboard/DashboardAdminPage'
import { DashboardReportsAdminPage } from '../components/admin/dashboard/DashboardReportsAdminPage'
import { UserAdminPage } from '../components/admin/users/UserAdminPage'
import { ReportsCourseAdminPage } from '../components/admin/reports/ReportsCourseAdminPage'
import { ProfilePageAdmin } from '../components/admin/profile/ProfilePage'
import { UserCreateEditPage } from '../components/admin/users/UserCreateEditPage'
import { LastSessionAdminPage } from '../components/admin/lastsession/LastSessionAdminPage'
// Archivo con rutas activas en sitio eanx
export const AppRouter = () => {
  const [user, setUser] = useState('');

  return (
    <UserContext.Provider value={{
      user,
      setUser,
    }}>

      <BrowserRouter>
        <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/*' element={<NotFoundPage />} />
            <Route path='/planes' element={<PlanPage />} />
            <Route path='/perfil' element={<ProfilePage />}/>
            <Route path='/registro' element={<SelectRegister />} />
            <Route path='/cursos' element={<CatalogPage/>} />
            <Route path='/curso/:courseID' element={<CourseDetailPage />} />
            <Route path='/contactanos' element={<ContactUsPage />} />
            <Route path='/verificar-email' element={<VerifyEmailPage />} />
            <Route path='/tipo-de-pago' element={<PaymentTypePage />} />
            <Route path='/cuenta-creada' element={<AccountCreatedPage />} />
            <Route path='/ingreso' element={<LoginPage isAdmin={false} />} />
            <Route path='/recuperar-contrasena' element={<PasswordRecoveryPage />} />
            <Route path='/restablecer-contrasena' element={<ResetPasswordPage />} />
            <Route path='/mensaje-contrasena-enviado' element={<PasswordMessageSentPage />} />  
            <Route path='/cambio-contrasena-exitoso' element={<SuccessfulKeyChangePage />} /> 
            <Route path='/actualizar-datos' element={<IdVerificationPage />} />
            <Route path='/verificar-datos' element={<IdVerificationPage />} />
            <Route path='/registrar' element={<UpdateData />} />
            <Route path='/proximamente' element={<ComingSoonPage />} />
            <Route path='/planes/:slugplan' element={<DirectRegister />} />
            <Route path='/admin/ingreso' element={<LoginPage isAdmin={true} />} />
            <Route path='/admin' element={<LoginPage isAdmin={true} />} />
            <Route path='/admin/dashboard' element={<DashboardAdminPage />} />            
            <Route path='/admin/dashboardreport' element={<DashboardReportsAdminPage />} />
            <Route path='/admin/reportescursos' element={<ReportsCourseAdminPage />} />
            <Route path='/admin/ultimasesion' element={<LastSessionAdminPage />} />
            <Route path='/admin/usuarios' element={<UserAdminPage />} />
            <Route path='/admin/usuarios/detalles' element={<UserCreateEditPage />} />
            <Route path='/admin/profile' element={<ProfilePageAdmin />} />

        </Routes>
      </BrowserRouter>  
    </UserContext.Provider>
  )
}
