import { Box, Dialog, LinearProgress, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { AcceptDialog } from "../controls/UIControls";
export const PAGE_SIZE = 5;
export const MINIMUM_CERT_SCORE = 70;
export const landingImages = require.context('../../assets/images', true);
const loadingImage = landingImages('./icons/loading_spin_ean.svg');
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para redireccionar a curso en learnworlds o mostrar mensaje de error
 * @function arrayPaginated
 */
export const arrayPaginated = (array, page) =>{
    return array.slice((page-1)*PAGE_SIZE, (page)*PAGE_SIZE)
}
export const TakeCourseIndicators = ({showWait, showError, errorCloser}) => {
    return <>
        <AcceptDialog 
        open={showError} 
        message="Ocurrió un error en la suscripción al curso, por favor intenta más tarde"
        style='error'
        afterClose={errorCloser}></AcceptDialog>

        <Dialog open={showWait}  
            disableScrollLock={ true }
            classes={{paper:'CourseDetail__wait-message'}}>
            <div >
                <img alt='Loader' src={loadingImage}/>
                <div>Espera un momento. En breve serás redireccionado a la página del curso... </div>  
            </div>
        </Dialog>
    </>;
}
// Función para mostrar indicador de progreso en curso
export const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};


