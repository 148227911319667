import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken';
import { RegisterWithPaymentPage } from '../registerwithpayment/RegisterWithPaymentPage';
import { RegisterPage } from '../register/RegisterPage';
import { useFetch } from '../../hooks/useFetch';
import { PlanPage } from '../plan/PlanPage';
import { useGetPlan } from '../../hooks/useGetPlan';
import { createSplit } from '../controls/UIControls';
import { useNavigate } from 'react-router-dom';
import { apiConfig } from '../../api/apiConfig';
// Página de registro directo a la plataforma según enlace de plan
export const DirectRegister = () => {
    const { slugplan } = useParams();
    const [dataPlan, setDataPlan] = useState(undefined);
    const { isLogged, authToken, getSessionUser } = useAuthToken();
    const [data, handleFetch] = useFetch();
    const logged = isLogged();
    const {getPlan} = useGetPlan();
    const navigator = useNavigate();
    // useEffect para consultar planes disponibles
    useEffect(()=> {
        handleFetch({
          url:`bo/plans?companyId[$eq]=${apiConfig.company}`,
          method: 'GET'
        })
        .then (async plans => {
          let aux = false
          let availPlans = plans.map((plan)=> {
            let slug = createSplit(plan.title) // Se crea slug utilizando el nombre del plan
            if(slug===slugplan){
              aux = true
                setDataPlan({ id: plan.id, name: plan.title, price: plan.price, duration: plan.duration, durationPeriod: plan.durationPeriod, type: plan.type })
                if(logged&&plan.price!==0){ // si el usuario ya está logueado y el plan es pago, se redirige a mercadopago
                    const user = getSessionUser();
                    getPlan(user,{id:plan.id, name: plan.title, price: plan.price}, false)
                 }
            }
            return {
                "slug": createSplit(plan.title),
                "duration": plan.duration,
                "durationText": plan.durationText,
                "id": plan.id,
                "name": plan.title.toUpperCase(),
                "price": plan.price,
                "plan_price": plan.price,
                "plan_duration": plan.duration,
                "plan_duration_period":plan.durationPeriod,
              }
          })
          if(aux===false){
            navigator('/planes')
          };
        });
      },[slugplan]);
    return (
        <div>
            { dataPlan!=undefined ? 
                dataPlan?.price === 0 ? <RegisterPage dataPlan={dataPlan} /> : <RegisterWithPaymentPage dataPlan={dataPlan} />
                : <PlanPage />
             }
        </div>
    )
}