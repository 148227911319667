import {  Dialog, IconButton, Snackbar } from '@mui/material';
import moment from 'moment';
import React, { useRef, useEffect, useState, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { PLAN_STATUS_ACTIVE, PLAN_STATUS_PENDING, PLAN_STATUS_EXPIRED, STICKY_CLOSED_KEY, STICKY_DAILY_SHOW, STICKY_FIRST_SHOW, STICKY_FIRST_SHOW_KEY, STICKY_SECOND_SHOW, STICKY_SECOND_SHOW_KEY, STICKY_SHOW_KEY } from '../../assets/constants';
import { UserContext } from '../../context/UserContext';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useUserPlan } from '../../hooks/useUserPlan';
import '../../styles/components/controls/UIControls.scss'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función ojo ver password
 * @function PwdEye
 */
const PwdEye = props => {
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const toggleInput = () => {
        setPasswordIsVisible(!passwordIsVisible);    
    }
    return (
        <div className="input-group">
            <input
                type={passwordIsVisible?'text':'password'} 
                onChange = {props.onChange}
                onBlur={props.onBlur}
                name={props.name}
                className={props.className}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
            />
            <span className={`input-group-text PwdEyeBox ${props.error?'PwdEyeBox--error':''}`} onClick={toggleInput}>
                <i className={`PwdEyeBox__icon fa-solid fa-eye${passwordIsVisible?'-slash':''}`}></i>
            </span>
        </div>
    )
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar pasos en registro de usuario
 * @param {string} title Titulo para operación
 * @param {HTMLElement} content Elementos HTML de cada componente a mostrar
 * @param {string} header Encabezado a mostrar
 * @param {string} containerClass Clase de estilo principal
 * @param {string} containerSubClass Clase de estilo secundario
 * @param {string} registerPay Número de paso en la que se encuentra
 * @function AuthFormContainerPage
 */
const AuthFormContainerPage = ({title, content, header, containerClass, containerSubClass, registerPay}) => {
    const landingImages = require.context('../../assets/images', true);
    if (!header)
        header = <Link to="/">
            <img
                alt='LiveHappy'
                src={landingImages('./logo-livehappy.png')}
                style={{width: "100px", height: "auto", marginBottom: "15px"}}
                className="mx-auto d-block"
            />
        </Link>;
    return (
        <div className={`auth__main min-vh-100 ${containerClass?containerClass:''}`}>
            <div className={`auth__box-container my-auto auth__box-container--rounded-5 ${containerSubClass?containerSubClass:''}`}>
                {header}
                <h3 className='text-center' style={{fontWeight: 700}}>{title}</h3>

                {registerPay?
                    <>
                        <div className='auth__numbers-container'>

                            <div className='auth__number-passed' >
                                1
                            </div>
                            <hr className='auth__numbers-line-active'/>
                            <hr className={`${registerPay > 1? 'auth__numbers-line-active' :'auth__numbers-line-blocked'}`} />
                            <div className={`${registerPay > 1? 'auth__number-passed' :'auth__number-blocked'}`}>
                                2
                            </div>
                            <hr className={`${registerPay > 1? 'auth__numbers-line-active' :'auth__numbers-line-blocked'}`}/>
                            <hr className={`${registerPay > 2? 'auth__numbers-line-active' :'auth__numbers-line-blocked'}`}/>

                            <div className={`${registerPay > 2? 'auth__number-passed' :'auth__number-blocked'}`}>
                                3
                            </div>
                        </div>
                        <div className='auth__numbers-text-container mb-3'>
                            <div className='auth__margin-steps-left'>
                            </div>
                            <div className='auth__number-text-active'>
                                Registro
                            </div>
                            <div className={`${registerPay > 1 ? 'auth__number-text-active' :'auth__number-text-blocked'}`}>
                                Validación de correo
                            </div>
                            <div className={`${registerPay > 2? 'auth__number-text-active' :'auth__number-text-blocked'}`}>
                                Pago
                            </div>
                            <div className='auth__margin-steps-right'>
                            </div>
                        </div>
                    </>
                    :
                    null
                }

                {content}
            </div>
        </div>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar mensajes de error
 * @function ErrorMessage
 */
const ErrorMessage = (props) => {
    return (
        <div className={`auth__errorMessage ${props.className?props.className:''}`}>
            <span role="alert" dangerouslySetInnerHTML={{ __html: props.message }}>
            </span>
        </div>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para submit en botones principales
 * @param {boolean} loading Variable que indica si está cargando o en espera
 * @param {string} main_title Título del botón
 * @param {string} wait_title Palabra a mostrar mientras espera que cargue función
 * @function SubmitFormButton
 */
const SubmitFormButton = ({loading, main_title, wait_title}) => {
    return (
        <button
        type="submit"
        className={`mb-1 auth__btn-form ${loading?'auth__btn-form--disabled':''}`}
        disabled={loading}
        >
        {!loading && (<span>{main_title}</span>)}
        {loading && (<><span> {wait_title} </span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) }
        </button>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar mensajes en modal
 * @param {boolean} open Variable que indica si se muestra o se oculta
 * @param {string} message Mensaje a mostrar en Modal de dialogo
 * @param {string} button_text Palabra a mostrar en el botón principal
 * @param {string} title Título a mostrar en el Modal de dialogo
 * @param {boolean} afterClose Variable que indica si se cierra el modal
 * @param {boolean} secondary_button Variable que indica si se utilizará un botón secundario
 * @param {string} secondary_button_text Texto del botón secundario 
 * @param {boolean} cancelClose Variable que indica si se cancela la función (oculta el modal)
 * @function AcceptDialog
 */
const AcceptDialog = ({open, message, button_text, title, style, afterClose, secondary_button, secondary_button_text, cancelClose }) => {
    const [openDialog, setOpenDialog] = useState(open);
    useEffect(()=> {setOpenDialog(open)},[open]);
    const closeDialog = () => {
        setOpenDialog(false);
        if(afterClose) afterClose();
    }
    const closeCancelDialog = () => {
        setOpenDialog(false);
        if(cancelClose) cancelClose();
    }
    if(!button_text) button_text='Aceptar';
    if(!secondary_button_text) secondary_button_text ='Cancelar';
    let icon;
    switch(style) {
        case 'ok':
            icon="check";
            break;
        case 'error':
            icon='xmark';
            break;
        case 'warning':
            icon='triangle-exclamation';
            break;
        case 'info':
            icon='info';
            break;
        default:
            icon='face-flushed';
    }
    return (
        openDialog && <Dialog open={openDialog} classes={{paper:'AcceptDialog__container'}}  disableScrollLock={ true }>
             <div>
                 {title && <div className='AcceptDialog__title'>{title}</div>}
                <div className='AcceptDialog__icon'><i className={`fa-solid fa-4x fa-${icon} icon${style?'--'+ style:''} `}></i></div>
                <div className='AcceptDialog__body'>{message}</div>
                <div className='AcceptDialog__button'>
                    <button className='ActionButton ActionButton--primary'  onClick={()=>closeDialog()}>{button_text}</button>
                    { secondary_button ? <button className='ActionButton ActionButton--secondary'  onClick={()=>closeCancelDialog()}>{secondary_button_text}</button> : '' }
                </div>
            </div>
        </Dialog>
    );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar mensajes exitosos
 * @param {boolean} open Variable que indica si se muestra o se oculta
 * @param {string} message Mensaje a mostrar en Modal de dialogo
 * @param {string} title Título a mostrar en el Modal de dialogo
 * @param {boolean} afterClose Variable que indica si se cierra el modal
 * @function SuccessDialog
 */
const SuccessDialog = ({open, message, title, afterClose}) =>{
    const [openDialog, setOpenDialog] = useState(open);
    useEffect(()=> {setOpenDialog(open)},[open]);
    const closeDialog = () => {
        setOpenDialog(false);
        if(afterClose) afterClose();
    }
    // setTimeout(()=>closeDialog(), 5000);
    return(
        openDialog && <Dialog open={openDialog} sx={ {'& .MuiBackdrop-root':{backgroundColor: 'transparent'}, '& .MuiPaper-root':{boxShadow: 'none'}}}>
            <div>
                {title && <div className='AcceptDialog__title'>{title}</div>}
                <div className='AcceptDialog__body'>{message}</div>
                
            </div>
        </Dialog>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar mensajes de error
 * @param {boolean} open Variable que indica si se muestra o se oculta
 * @param {string} message Mensaje a mostrar en Modal de dialogo
 * @param {string} title Título a mostrar en el Modal de dialogo
 * @param {boolean} afterClose Variable que indica si se cierra el modal
 * @function ErrorDialog
 */
const ErrorDialog = ({open, message, title, afterClose}) =>{
    const [openDialog, setOpenDialog] = useState(open);
    useEffect(()=> {setOpenDialog(open)},[open]);
    const closeDialog = () => {
        setOpenDialog(false);
        if(afterClose) afterClose();
    }
    // setTimeout(()=>closeDialog(), 5000);
    return(
        openDialog && <Dialog open={openDialog} 
                              sx={ {'& .MuiModal-root': {top: 'auto', right: 'auto'},
                                    '& .MuiBackdrop-root':{backgroundColor: 'transparent'}, 
                                    '& .MuiPaper-root':{boxShadow: 'none'}}}
                              className='Dialog__position'
                      >
            <div style={{border: "1px solid #F9463B", borderRadius: "8px"}}>
                <div style={{display:"flex", flexWrap: "wrap"}}>
                {/* <div className='row'> */}
                    <div className='ErrorModal__icon-container'>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                            <i className='fa-solid fa-circle-xmark' style={{fontSize:"33px"}}></i>
                        </div>
                    </div>
                    <div className='ErrorModal__text-container' >
                        <div className='AcceptDialog__title'>{title}</div>
                        <div className='AcceptDialog__body'>{message}</div>
                    </div>
                </div>
                
            </div>
        </Dialog>
    )
}
// Función para mostrar dialogos de mensajes 
const MessageDialog = ({open, message, button_text, title, image, 
                    afterClose, secondary_button, secondary_button_text, showCloseButton,
                    cancelClose, messagetwo, messagethree, messagelink, link }) => {
    const [openDialog, setOpenDialog] = useState(open);
    useEffect(()=> {setOpenDialog(open)},[open]);
    const closeDialog = () => {
        setOpenDialog(false);
        if(afterClose) afterClose();
    }
    const closeCancelDialog = () => {
        setOpenDialog(false);
        if(cancelClose) cancelClose();
    }
    if(!button_text) button_text='Aceptar';
    if(!secondary_button_text) secondary_button_text ='Cancelar';
    return (
        openDialog && <Dialog open={openDialog} classes={{paper:'AcceptDialog__container'}}  disableScrollLock={ true }>
            {
                (showCloseButton && closeCancelDialog) &&
                (        
                <IconButton
                    aria-label="close"
                    onClick={closeCancelDialog}
                    sx={{
                    position: 'absolute',
                    right: 5,
                    top: -2,
                    }}
                >
                <i className="fa-solid fa-xmark"></i>
                </IconButton>) 
            }
            
            <div>
                {title && <div className='AcceptDialog__body'>{title}</div>}
                { image && <div className='AcceptDialog__image'></div> }
                <div className='AcceptDialog__message' dangerouslySetInnerHTML={{ __html: message }} />
                { messagetwo && <div className='AcceptDialog__message' dangerouslySetInnerHTML={{ __html: messagetwo }} /> }
                <div className='AcceptDialog__button'>
                    <button className='ActionButton ActionButton--primary'  onClick={()=>closeDialog()}>{button_text}</button>
                    { secondary_button ? <button className='ActionButton ActionButton--secondary'  onClick={()=>closeCancelDialog()}>{secondary_button_text}</button> : '' }
                </div>
                { messagethree && <div className='AcceptDialog__message mb-2'>{messagethree} { messagelink && <Link to={link} className='AcceptDialog__link'>{messagelink}</Link> }</div> }
            </div>
        </Dialog>
    );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar toast
 * @param {string} title Título a mostrar en el toast
 * @param {string} message Mensaje a mostrar en toast
 * @param {string} duration Variable que indica la duración en segundos para mostrar el toats
 * @param {boolean} show Variable que indica si se activa el toast
 * @param {string} position Ubicación para mostrar toast (Por defecto abajo a la izquierda)
 * @param {string} type Tipo de mensaje (success o error)
 * @param {event} closeCallback Función para cerrar el toast
 * @function Toast
 */
const Toast = ({title, message, duration, show, position, type, closeCallback}) => {
    const [open, setOpen] = useState(show);

    const handleClose = () => {
        setOpen(false);
        if(closeCallback) closeCallback();
    }

    useEffect(()=> setOpen(show),[show]);

    if(!!!position) position ={};
    position = Object.assign({vertical:'bottom', horizontal: 'center'}, position);
    

    duration = duration || 5000;

    let icon;
    switch(type) {
        case 'success':
            icon = 'circle-check';
            break;
        case 'pending':
            icon = 'clock';
            break;
        case 'error':
            icon = 'circle-xmark';
            break;
    }

    return (
        <Snackbar
        open={open}
        autoHideDuration={duration}
        anchorOrigin={position}
        onClose={handleClose}
        >
        <div className={`Toast Toast--${type}`}>
            <div className={`Toast__container`}>
                <div className={`Toast__icon Toast__icon--${type}`}>
                    <i className={`fa-regular fa-${icon}`}></i>
                </div>
                <div  className={`Toast__text`}>
                    {title && <div className={`Toast__title Toast__title--${type}`}>{title}</div>}   
                    <div dangerouslySetInnerHTML={{ __html: message }}></div>
                </div>
            </div>
        </div>
    </Snackbar>        

    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función tiempo restante para sticky banner
 * @param {string} value Valor de tiempo a mostrar en el sticky banner
 * @param {string} title Título a mostrar en el sticky banner
 * @function RemainingTimeNumber
 */
const RemainingTimeNumber = ({value,title}) => {
    return  (                   
        <div>
            <div className='col-4 ProfilePage__data-remaining-days-bgNumber'>{(value<10)?'0'+value:value}</div>
            <div className='ProfilePage__data-remaining-days-period'>{title}</div>
        </div>
        )    
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para mostrar sticky banner
 * @param {string} message Mensaje a mostrar en sticky banner
 * @param {event} cancelClose Función para cerrar el sticky banner
 * @param {event} primaryButtonAction Función para botón principal
 * @param {event} secondaryButtonAction Función para botón secundario
 * @function BannerRemainingDays
 */
const BannerRemainingDays = ({
        message,
        title ='¡Te queda poco tiempo!', 
        primary_button_text = 'Ir a los planes',  
        secondary_button_text = 'Soy Comunidad Eanista', 
        cancelClose, 
        primaryButtonAction, 
        secondaryButtonAction,
        }) => {
    const {user} = useContext (UserContext);
    const [cookies, setCookie] = useCookies([STICKY_FIRST_SHOW_KEY, 
                                                            STICKY_SECOND_SHOW_KEY
                                                            ]);
    const navigate = useNavigate();
    
    if(message==undefined) message=`Hola ${user.name}, tu suscripción está a punto de vencer. Para seguir aprendiendo y evolucionando continúa con nosotros y escoge un plan.`; 
    if(primaryButtonAction==undefined) primaryButtonAction= (() => navigate('/planes'));
    if(secondaryButtonAction==undefined) secondaryButtonAction = (() => navigate('/verificar-datos'));

    //Función para timer
    const stopTimer = () => {
        if (Ref.current) clearInterval(Ref.current);
    }
    //Función para cerrar banner
    const closeBanner = () => {
        stopTimer();
        sessionStorage.removeItem(STICKY_SHOW_KEY);
        sessionStorage.setItem(STICKY_CLOSED_KEY,true);
        setShow(false);
    }

    const primaryAction = () => {
        if(primaryButtonAction) primaryButtonAction();
    }

    const secondaryAction = () => {
        if(secondaryButtonAction) secondaryButtonAction();
    }

    //Función para cerrar mensaje de dialogo
    const closeCancelDialog = () => {
        closeBanner();
        if(cancelClose) cancelClose();
    }


    const Ref = useRef(null);
    const remaningDays = useRef(0);
    const [timer, setTimer] = useState({total:0,days:0,hours:0,minutes:0,seconds:0});
    const {authToken} = useAuthToken();
    const {checkUserPlan} = useUserPlan();
    const [show, setShow] = useState(false);

    const getTimeRemaining = (e) => {
        const now = moment();
        const end = moment(e);
        const remaining = moment.duration(end.diff(now));
        return {
            total: Math.trunc(remaining.asSeconds()), 
            days:Math.trunc(remaining.asDays()), 
            hours:remaining.hours(), 
            minutes:remaining.minutes(), 
            seconds:remaining.seconds(),
        };
    }
    //Función para iniciar timer en banner
    const startTimer = (e) => {
        let { total, days, hours, minutes, seconds }
            = getTimeRemaining(e);
        setTimer({ total, days, hours, minutes, seconds });
        if (total <= 0) stopTimer();
    }

    //Función para limpiar timer
    const clearTimer = (e) => {
        stopTimer();
        setTimer({days:0,hours:0,minutes:0,seconds:0});
        startTimer(e);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
    //Función para obtener cookies de banner
    const setStickyShowCookies = (remainingDays, cookieKey=null, substractFromRemaining=null) => {
        const SECONDS_PER_DAY = 24*60*60;
        if(cookieKey!=null && substractFromRemaining!=null) {
            setCookie(cookieKey, true, {maxAge:(remainingDays-substractFromRemaining)*SECONDS_PER_DAY});
        }
        if(sessionStorage.getItem(STICKY_CLOSED_KEY)==null) sessionStorage.setItem(STICKY_SHOW_KEY,true);
        setShow(true);
    }
    //Función para mostrar banner
    const showSticky = () => {
        const remDays = remaningDays.current;
        if(STICKY_FIRST_SHOW>=remDays && remDays>STICKY_SECOND_SHOW) { // entre el primer y el segundo aviso
            if(cookies[STICKY_FIRST_SHOW_KEY]==undefined) {
                setStickyShowCookies(remDays,STICKY_FIRST_SHOW_KEY,STICKY_SECOND_SHOW);
            }
        } else if(STICKY_SECOND_SHOW>=remDays && remDays>STICKY_DAILY_SHOW) {
            if(cookies[STICKY_SECOND_SHOW_KEY]==undefined) {
                setStickyShowCookies(remDays,STICKY_SECOND_SHOW_KEY,STICKY_DAILY_SHOW);
            }
        } else if(remDays<=STICKY_DAILY_SHOW) {
            setStickyShowCookies(remDays);
            console.log(`Aviso diario a partir de los ${STICKY_DAILY_SHOW} dias`);
        } else {
            stopTimer();
        }
    }

    
    useEffect(() => {
        if(authToken) {
            checkUserPlan(authToken)
            .then (currPlan => {
                if(currPlan ) {
                    if(!currPlan.pending) {
                        const endDate = currPlan.endsAt;
                        let { days } = getTimeRemaining(endDate);
                        remaningDays.current = days;
                        clearTimer(endDate);
                        showSticky();
                        setShow(sessionStorage.getItem(STICKY_SHOW_KEY)!==null);
                    }
                } else {
                    setBannerContent({
                        title: '!Tu plan ha vencido!',
                        message:  'No dejes de acceder a la educación sin barreras. Encuentra el plan que mas se ajuste a lo que necesitas'
                    })
                    showSticky();
                    setShow(sessionStorage.getItem(STICKY_SHOW_KEY)!==null);
                }
            });
        }
        return stopTimer;
    }, []);
    
    const [bannerContent,setBannerContent] = useState({title, message});

    return (show?
        (<div className='ProfilePage__data-remaining-days-container'>
            <div className='ProfilePage__data-remaining-days '>
            <div style={{height:'1.3em', position:'relative', margin:'0px -20px'}}>
                <IconButton
                    aria-label="close"
                    onClick={closeCancelDialog}
                    sx={{
                        position: 'absolute',
                        right: 5,
                        top: -10,
                        color: '#FFFFFF',
                    }}
                >
                    <i className="fa-solid fa-xmark"></i>
                </IconButton>
                </div>
                <div className={`row mt-2 text-start`}>
                <div className='d-flex justify-content-center col-md-6 col-lg-4'>
                    <RemainingTimeNumber value={timer.days} title='Días'></RemainingTimeNumber>
                    <RemainingTimeNumber value={timer.hours} title='Horas'></RemainingTimeNumber>
                    <RemainingTimeNumber value={timer.minutes} title='Minutos'></RemainingTimeNumber>
                    <RemainingTimeNumber value={timer.seconds} title='Segundos'></RemainingTimeNumber>
                </div>
                <div className='col-md-6 col-lg-5'>
                    {bannerContent.title && <div className='ProfilePage__title-remaining-days'>{bannerContent.title}</div>}
                    <div className='ProfilePage__text-remaining-days'>{bannerContent.message}</div>
                </div>
                <div className='col-lg-3'>
                    <div className='Remaining-days__button flex-md-row'>
                        <button className='ActionButton--remaining-days ActionButton--remaining-days--primary'  onClick={()=>primaryAction()}>{primary_button_text}</button>
                        <button className='ActionButton--remaining-days ActionButton--remaining-days--secondary'  onClick={()=>secondaryAction()}>{secondary_button_text}</button>
                    </div>
                </div>
                </div>

            </div>
        </div>)
        :null
    );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para remover acentos de una cadena recibida por parámetros
 * @param {string} cadena Cadena de texto a la que se le van a eliminar los acentos
 * @function removeAccents
 */
const removeAccents = (cadena) =>{
    const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U','¡':'','!':''};
    return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para reemplazar una cadena por otra dentro de un string recibido por parámetros
 * @param {string} word Cadena de palabras donde se va a reemplazar las palabras
 * @param {string} before Frase a buscar para reemplazar por la nueva cadena
 * @param {string} after Frase que se agregará en la cadena
 * @function removeAccents
 */
const removeSpaces = (word,before,after) => {
    return word.replace(before,after)
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para generar split utilizado en slug de rutas de planes
 * @param {string} cadena Cadena de texto para convertirla en slug palabra-otrapalabra
 * @function createSplit
 */
const createSplit = (cadena) => {
    var indices = 1;
    let nuevacadena = ''
    for(var i = 0; i < cadena.length; i++) {
        if (cadena[i].toLowerCase() === " ") indices++;
    }
    nuevacadena = removeAccents(cadena)
    for(var i = 0; i < indices; i++) {
        nuevacadena = removeSpaces(nuevacadena.toLowerCase()," ","-")
    }
    for(var i = 0; i < nuevacadena.length; i++) {
        if(nuevacadena.charAt(i)=="!"){
            nuevacadena = removeSpaces(nuevacadena.toLowerCase(),'!',"")
        }else if(nuevacadena.charAt(i)=="¡"){
            nuevacadena = removeSpaces(nuevacadena.toLowerCase(),'¡',"")
        }
    }
    return nuevacadena
}
let today = (new Date());
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para consultar estado de los planes
 * @param {date} dateStart Fecha de inicio para 
 * @param {date} dateEnd Cadena de texto para convertirla en slug palabra-otrapalabra
 * @function createSplit
 */
const getPlanStatus = (dateStart,dateEnd) => {
    let status;
    if(new Date(dateEnd).getTime() >= today && new Date(dateStart).getTime() <= today) {
        status = PLAN_STATUS_ACTIVE;
    } else if(new Date(dateStart).getTime() > today) {
        status = PLAN_STATUS_PENDING;
    } else if (new Date(dateEnd).getTime() <= today && new Date(dateStart).getTime() <= today) {
        status = PLAN_STATUS_EXPIRED;
    }
    return status;
}
const calculatePastDays = (date) => {
    if (date) {
      const dateEntered = new Date(date);
      const currentDate = new Date();
      const differenceinmilliseconds = currentDate - dateEntered;
      const pastDays = Math.floor(differenceinmilliseconds / (1000 * 60 * 60 * 24));
      return pastDays;
    }
  };
export {calculatePastDays, getPlanStatus, createSplit, PwdEye, AuthFormContainerPage, ErrorMessage, SubmitFormButton, AcceptDialog, SuccessDialog, ErrorDialog, MessageDialog, Toast, BannerRemainingDays};