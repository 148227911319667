

export const courseCategories = [
    {
        'id': 1,
        'name': 'Marketing y Ventas',
        'image': 'marketing-y-ventas.jpg',
        'icon': 'mark-ventas'
    },
    {
        'id': 2,
        'name':  'Emprendimiento, Gerencia y Administración',
        'image': 'emprendimiento-gerencia-y-administracion.jpg',
        'icon': 'emp-ger-adm'
    },
    {
        'id': 5,
        'name':  'Finanzas, Economía e Inversión',
        'image': 'finanzas-economia-e-inversion.jpg',
        'icon': 'fin-eco-inv'
    },
    {
        'id': 7,
        'name':  'Tecnología',
        'image': 'tecnologia.jpg',
        'icon': 'tecno'
    },
    {
        'id': 3,
        'name':  'Proyectos e Innovación',
        'image': 'proyectos-e-innovacion.jpg',
        'icon': 'proy-inno'
    },
    {
        'id': 8,
        'name':  'Analítica y Ciencia de Datos',
        'image': 'analitica-y-ciencia-de-datos.jpg',
        'icon': 'idiomas'
    },
    {
        'id': 4,
        'name':  'Sostenibilidad e Inclusión',
        'image': 'sostenibilidad-e-inclusion.jpg',
        'icon': 'sost-incl'
    },
    {
        'id': 6,
        'name':  'Habilidades Humanas',
        'image': 'habilidades-humanas.jpg',
        'icon': 'hab-hum'
    },
]