import { Box, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCourseTaker } from '../../hooks/useCourseTaker';
import { arrayPaginated, LinearProgressWithLabel, MINIMUM_CERT_SCORE, PAGE_SIZE, TakeCourseIndicators } from './shared';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para cursos en progreso
 * @function InProgressCourses
 */
export const InProgressCourses = ({myCourses, newCoursesOnly = false}) =>{

    const [coursesDisplayed, setCoursesDisplayed] = useState([]);
    const [errorDialog, setErrorDialog] = useState(false);
    const [data,courseTaker] = useCourseTaker();

    const coursesFiltered = myCourses.filter((e)=>{
        const progress = e['user_study.progress'];
        const avgScore = e['user_study.avgScore'];
        if(newCoursesOnly){
            return progress ===0
        }else{
            return progress>0 && (progress<100 || avgScore < MINIMUM_CERT_SCORE);
        }
    });

    const onCourseRedirection = (course) => {
        courseTaker(course, () => setErrorDialog(true) );
    }

    useEffect(() => {
        setCoursesDisplayed(arrayPaginated(coursesFiltered, 1));

    }, [myCourses])
    
    const numberOfPages = Math.ceil(coursesFiltered.length/PAGE_SIZE);
    
    const handleChangePage = (event, page) =>{
        setCoursesDisplayed(arrayPaginated(coursesFiltered, page));
        window.scrollTo(0,0);
    }
    
    return(
        <>
        <TakeCourseIndicators 
                    showWait={data.loading} 
                    showError={errorDialog} 
                    errorCloser={()=>setErrorDialog(false)} />
            <div className='row'>
                <div className='col-12'>
                    {coursesDisplayed.length===0 &&
                        <div className="col mx-sm-5">
                            <div className="text-center text_primary my-5" >
                                <i className="fa-solid fa-book fa-4x mb-3"></i>
                                <p style={{fontSize: "20px"}}>No has iniciado ningún curso <br /><Link className='ProfilePage__link-to-course' to='/cursos' style={{fontWeight: "600"}}>Visita nuestro catálogo</Link></p>
                            </div>
                        </div>
                    }
                    {coursesDisplayed.map((course, index)=>
                        <div key={index} className='d-block d-md-flex ProfilePage__course-in-progress-row'>
                            <div className='d-flex justify-content-center me-md-3' style={{marginBottom: '15px'}}>
                                <img className='ProfilePage__in-progress-course-image' src={course.imageUrl} onClick={()=>{onCourseRedirection(course)}}/>
                            </div>
                            <div className='col-12 col-md-7 col-lg-7 col-xl-7'>
                                <div className='row' onClick={()=>{onCourseRedirection(course)}}>
                                    <span className='ProfilePage__course-title'>{course.title}</span>
                                </div>
                                <div className='row'>
                                    <span className='ProfilePage__course-instructor-name'>Por {course.instructorData.name}</span>
                                </div>
                                <div className='row'>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressWithLabel value={course['user_study.progress']} />
                                    </Box>
                                </div>
                            </div>
                        </div>              
                    )}
                </div>
            </div>
            {
                coursesDisplayed.length>0 &&
                    (<div className='row mb-3'>
                        <div className='col-12 d-flex justify-content-center'>
                            <Pagination size='small' count={numberOfPages} onChange={handleChangePage} />
                        </div>
                    </div>)
            }
        </>
    )
}

