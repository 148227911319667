// Condiciones para validar password
export const PasswordValidations = [
    {
        id:"min6",
        label: 'Mínimo 6 caracteres.',
        pattern: /.{6,}/
    },
    {
        id:"upper",
        label: 'Al menos una mayúscula,',
        pattern: /[A-Z]/
    },
    {
        id:"lower",
        label: 'una minúscula,',
        pattern: /[a-z]/
    },
    {
        id:"number",
        label: 'un número,',
        pattern: /[0-9]/
    },
    {
        id:"special",
        label: 'un caracter especial',
        pattern: /[!"'`#$%&@|^_~,.?:;*+-/=<>{}()[\]\\]/
    }
]