import { useState } from "react";
import { useFetch } from "./useFetch";
import { useNavigate } from "react-router-dom";
import { apiConfig } from '../api/apiConfig';
// Hook para gestionar planes
export const useGetCourse = () => {
    const [data, handleFetch] = useFetch();
    const [mpPreferenceCourse, setMpPreferenceCourse] = useState('null');
    const navigate = useNavigate();
    // Handle envío de información a mercagopago
    const mpPayment = (data, recurring=false) => {
        const action = recurring?'subscription':'preference'       
        handleFetch({
          url: `payments/mp_${action}`,
          method: 'POST',
          data
        }).then(p => {
            window.location.replace(p.init_point);
        })
    }
    // Handle para asignar plan a usuario, recibe por parámetro id de usuario, id de plan y código si completa operación por mercadopago
    const associateCourse = (user,course,code=null) => {
        return new Promise((resolve) => {
            let planAssociated='assigned';
            handleFetch({
                url: 'users/plans/users_courses',
                method: 'POST',
                data: {
                    userId: user.id,
                    courseId: course.courseId || course.id,
                    approvalCode: code,
                    companyId: apiConfig.company
                }
            })
            .then (course => {
                resolve(planAssociated);
            })
            .catch(e => {planAssociated='not_assigned'})
        })
    }
    // Función para asociar curso según condición, recibe id usuario, id curso y pago recurrente
    const getCourse =  (user, course, recurrent, fromRegister) => {
        let today = new Date();
        let day = today.getDate()<10 ? `0${today.getDate()}` : today.getDate();
        let month = today.getMonth()<10 ? `0${today.getMonth()+1}` : today.getMonth() + 1;
        let year = today.getFullYear();
        return new Promise((resolve) => {
            if(course.price==0) {
                associateCourse(user,course,`Curso cortesia ${day}/${month}/${year}`)
                .then(pa => resolve(pa));
            } else {
                const data = {
                    course: {
                        ...course,
                        returnUrl: apiConfig.mercadoPago.returnUrl,
                        returnUrlBack: apiConfig.mercadoPago.returnUrlBack,
                    },
                    user
                  }
                mpPayment(data,recurrent);
                resolve('purchase');
            }
        });
    }

    return {getCourse, associateCourse, mpPreferenceCourse};
}