import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función paginación para cursos
 * @param {event} onChangePage Función para cambio de página
 * @param {string} totalElements Número de total de elementos del array
 * @param {string} itemsPerPage Número de elementos a mostrar por página
 * @param {string} totalPages Número de elementos a mostrar por página
 * @param {string} forcePage Número de página a consultar
 * @function Pagination
 */
export const Pagination = ({onChangePage, totalElements, itemsPerPage, totalPages, forcePage}) => {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(totalElements / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    return (
        <div className="d-flex justify-content-center mt-3">
            { totalElements > 0 && (
                <ReactPaginate
                    className="pagination"
                    pageClassName="Pagination__page-item d-none d-sm-block mx-sm-1"
                    pageLinkClassName="Pagination__page-link page-link"
                    activeClassName="Pagination__page-item--active"
                    previousClassName="Pagination__page-item"
                    nextClassName="Pagination__page-item"
                    previousLinkClassName="Pagination__page-link--nav page-link"
                    nextLinkClassName="Pagination__page-link--nav page-link"
                    breakLabel="..."
                    breakClassName='d-none d-sm-block'
                    nextLabel="Siguiente >"
                    onPageChange={onChangePage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    //pageCount={totalPages}
                    previousLabel="< Anterior"
                    renderOnZeroPageCount={null}
                    pageNum={4}
                    pageCount={pageCount}
                    forcePage={forcePage}
                />
            )}
        </div>
    )
}