import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { SimpleFooter } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { CourseDetailSection } from './CourseDetailSection';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para contenido de detalle de cursos
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface CourseDetailPage
 */
export const CourseDetailPage = () => {
    const {courseID} = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <LandingHeader />
            <div style={{marginTop: "70px"}}></div>
            <CourseDetailSection courseID={courseID} />
            <SimpleFooter />
        </div>
    )
}
