import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  isStacked: true,
  height: 400,
  backgroundColor: "transparent",
  legend: { position: "top", maxLines: 3 },
  vAxis: { minValue: 0 },
};

export function LineGoogleCharts({data}) {
  const tData = data && data.length !==0 && [
    ["Fecha", "Usuarios"],
    ...data.map(item => [item.date, parseInt(item.value)])
  ];
  return (
    tData && <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={tData}
      options={options}
    />
  );
}