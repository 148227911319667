import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  title: "Cursos finalizados",
  pieHole: 0.4,
  is3D: false,
  backgroundColor: "transparent"
};

export function GoogleDonutChart({data}) {
  const tData = data && data.length !==0 && [
    ["Curso", "Valor"],
    ...data.map(item => [item.name, parseInt(item.value)])
  ];
  return (
    tData && <Chart
      chartType="PieChart"
      data={tData}
      options={options}
      width={"100%"}
      height={"400px"}
    />)
}
