import React from 'react';
import ReactDOM from 'react-dom';
import {AppRouter} from './routes/AppRouter';
import './styles/styles.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <AppRouter />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

