import React, { useEffect, useState } from 'react'
import { PwdEye, AuthFormContainerPage } from '../controls/UIControls';
import { useForm, Controller } from 'react-hook-form';
import { useFetch } from '../../hooks/useFetch';
import { PasswordValidator } from '../controls/PasswordValidator';
import * as CONSTANTS from '../../assets/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
    MESSAGE_GENERIC_ERROR, 
    MESSAGE_CHANGE_PASSWORD_INVALID, 
} from '../../assets/constants'; 
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para cambiar contraseña en caso de olvido
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface ChangePasswordForm
 */
export const ChangePasswordForm = () => {
    const { register, handleSubmit, formState: { errors }, control, setError, watch } = useForm();
    const [fetchData, handleFetch] = useFetch();
    const [changePasswordError, setChangePasswordError] = useState('');
    const [confirmPasswordMatches, setConfirmPasswordMatches] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const navigator = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const rt = searchParams.get('rt');
    // Función de validación de contraseña
    const setValidPassword = (isValid) =>  {
        setPasswordIsValid(isValid);
        console.log('VALID PASSWORD',passwordIsValid);
    }

    const password=watch('password','');
    const password2=watch('password2','');

    useEffect(()=> {
        setConfirmPasswordMatches(password===password2);
    },[password, password2]);
    // Función para mostrar mensaje de error
    const ErrorMessage = (props) => {
        return (
            <div className={`auth__errorMessage ${props.className}`}>
                <span role="alert" dangerouslySetInnerHTML={{ __html: props.message }}>
                </span>
            </div>
        )
    }
    // Función de reseteo de contraseña, llama al endpoint y se envía token + contraseña nueva
    const handleChangePassword = (formValues) => {
        //setMessageSent(false);
        setChangePasswordError('');
        if(passwordIsValid && confirmPasswordMatches) {
            handleFetch({
                url:'users/reset',
                method: 'POST', 
                data: {
                    token:rt, 
                    password: formValues.password
                }
            })
            .then(r => {
                    console.log('FETCH RESULT CHANGE PASSWORD:',r); 
                    navigator('/cambio-contrasena-exitoso');         
                },
                error => {
                    console.log('FETCH ERROR CHANGE PASSWORD', error);
                    if(error.errors) {
                        let e = error.errors[0];
                        setChangePasswordError(e.message);
                    } else {
                        setChangePasswordError( MESSAGE_GENERIC_ERROR);
                    }
                }
            );
        } else {
            alert('Hay errores en el formulario');
        }
    }

    return (
        <form onSubmit={ handleSubmit(handleChangePassword) }>
            <div className="mb-3">
                <label className='auth__form-label'>Nueva contraseña <span className='auth__required-input'>*</span></label>
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                    render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                        return (
                        <PwdEye 
                            className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_PASSWORD_PLACEHOLDER}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            error={invalid}
                        >
                        </PwdEye>)
                        }
                    }
                />
                <PasswordValidator 
                value={password} 
                checkCriteria={setValidPassword}
                ></PasswordValidator>
                <ErrorMessage message={!passwordIsValid?CONSTANTS.MESSAGE_PASSWORD_IVALID:''}></ErrorMessage>
            </div>
            <div className="mb-3">
                <label className='auth__form-label'>Repite tu contraseña <span className='auth__required-input'>*</span></label>
                <Controller
                    name="password2"
                    control={control}
                    rules={{ required: CONSTANTS.MESSAGE_PASSWORD_REQUIRED }}
                    render={({field: { onChange, onBlur, name}, fieldState: {invalid} }) => {
                        return (
                        <PwdEye 
                            className = {`form-control auth__input ${invalid?"auth__input--error":""}`}
                            placeholder={CONSTANTS.LABEL_PASSWORD_CONFIRM_PLACEHOLDER}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            error={invalid}
                        >
                        </PwdEye>)
                        }
                    }
                />
                <ErrorMessage message={!confirmPasswordMatches?CONSTANTS.MESSAGE_PASSWORD_CONFIRM_DOESNT_MATCH:''}></ErrorMessage>
            </div>
            <button
                type="submit"
                className={`mb-1 auth__btn-form ${fetchData.loading?'auth__btn-form--disabled':''}`}
                disabled={fetchData.loading}
            >
                {!fetchData.loading && (<span>Restablecer</span>)}
                {fetchData.loading && (<><span> Procesando... </span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) }
            </button>
            <ErrorMessage className='mb-1 text-center auth__errorMessage--2lines' message={changePasswordError}/> 
        </form>
    )
}

export const ResetPasswordPage = () => {
    return (
        <AuthFormContainerPage title="Restablecer Contraseña" content={<ChangePasswordForm /> } />
    )
}