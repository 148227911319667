import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthToken } from '../../hooks/useAuthToken'
import { SimpleFooter } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { ProfileSection } from './ProfileSection'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función página de perfil
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface ProfilePage
 */
export const ProfilePage = () => {
  const {authRedirect} = useAuthToken(); 
  useEffect(() => {
    authRedirect();
  }, [])
  
  return (
    <div>
        <LandingHeader />
        <div style={{marginTop: "70px"}}></div>
        <ProfileSection />
        <SimpleFooter />
    </div>
  )
}
