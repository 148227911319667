import React, { useEffect, useState } from 'react'
import { useFetch } from '../../../hooks/useFetch';
import { Pagination } from './../../pagination';
import { Link } from 'react-router-dom';
import { Filters } from '../filters/Filters';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { apiConfig } from '../../../api/apiConfig';
const landingImages = require.context('../../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Listado sección usuarios
 * @interface UserAdminSection
 */
export const UserAdminSection = () => {

    const [users, setUsers] = useState([]);
    const [usersFull, setUsersFull] = useState([]);
    const [segment, setSegment] = useState([]);
    const [total, setTotal] = useState(1);
    const [data, handleFetch] = useFetch();    
    const [dataFilter, setDataFilter] = useState({
        document: '',
        email: '',
        lastname: '',
        name: '',
        companyId: '',
        type: '',
        until: '',
        since: '',
    })
    const viewSegments = () => {
        const responsesegment = handleFetch({
            url: `bo/segment?&companyId=${apiConfig.company}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsesegment
            .then((r) => {
                setSegment(r.data)
            },
                error => {
                    console.log(error.errors);
                })
    }
    const refreshUsers = () => {
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filtertype = dataFilter.type !== '' ? "&type=" + dataFilter.type : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filtercompany = "&companyId=" + apiConfig.company
        const response = handleFetch({
            url: `bo/webusers?${filtername}${filterlastname}${filteremail}${filterdocument}${filtertype}${filteruntil}${filtersince}${filtercompany}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsers(r.data)
                setTotal(r.total)
            },
                error => {
                    console.log(error.errors);
                })
        const responsefull = handleFetch({
            url: `bo/webuserscsv?$limit=50000${filtername}${filterlastname}${filteremail}${filterdocument}${filteruntil}${filtersince}${filtercompany}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        responsefull
            .then((r) => {
                setUsersFull(r.data);
            },
                error => {
                    console.log(error.errors);
                })
    }
    const { authToken } = useAuthToken(true);
    // Consulta de endpoint listado de usuarios
    useEffect(() => {
        window.history.replaceState(null, '');
        refreshUsers();
        viewSegments();
    }, [total])
    // Handle para registrar información de campos en filtros
    const handleChangeFilter = ({ target }) => {
        setDataFilter({
            ...dataFilter,
            [target.name]: target.value
        })
    }
    // Función para activar filtros, recibe parametros del mismo
    const onSubmit = async (e) => {
        e.preventDefault();
        refreshUsers();
    }
    let newDate = new Date()
    newDate = new Date(newDate.setDate(newDate.getDate())).toISOString().split('T')[0]
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <h1 style={{ fontSize: "36px", fontWeight: "700", lineHeight: "46.8px", letterSpacing: "-1%" }}>Usuarios</h1>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{ marginTop: "30px" }}>
                        <Filters
                            downloadCSV={true}
                            filename={"listusers.csv"}
                            onSubmit={onSubmit}
                            data={usersFull}
                        >
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Nombre</span>
                                <div>
                                    <input type='text' name='name' value={dataFilter?.name || ''} onChange={handleChangeFilter} placeholder="Nombre" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Apellido</span>
                                <div>
                                    <input type='text' name='lastname' value={dataFilter?.lastname || ''} onChange={handleChangeFilter} placeholder="Apellido" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Documento</span>
                                <div>
                                    <input type='number' name='document' value={dataFilter?.document || ''} onChange={handleChangeFilter} placeholder="Documento" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Correo</span>
                                <div>
                                    <input type='text' name='email' value={dataFilter?.email || ''} onChange={handleChangeFilter} placeholder="Correo electrónico" className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Segmentación</span>
                                <div>
                                    <select
                                        className={`form-select FilterAdmin__input`}
                                        name='type'
                                        value={dataFilter?.type || ''}
                                        onChange={handleChangeFilter}
                                    >
                                        <option value="" selected>Todos</option>
                                        {
                                            segment.length != 0 ?
                                                segment.map((segm, index) => {
                                                    return (<option value={segm.type}>{segm.name}</option>)
                                                }) : ''
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Inicio</span>
                                <div>
                                    <input type="date" name="since" value={dataFilter?.since || ''} onChange={handleChangeFilter} max={dataFilter?.until || newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                            <div className='FilterAdmin__options'>
                                <span className='FilterAdmin__input-title'>Fecha Fin</span>
                                <div>
                                    <input type="date" name="until" value={dataFilter?.until || ''} onChange={handleChangeFilter} min={dataFilter?.since} max={newDate} className={`FilterAdmin__input`} />
                                </div>
                            </div>
                        </Filters>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='' style={{ width: "90%", marginTop: "17px", overflowX: "auto" }}>
                            {
                                data.loading ?
                                    <Loading />
                                    :
                                    <UsersTable users={users} total={total} dataFilter={dataFilter} segment={segment} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para listar usuarios
 * @param {JSON} users Información de usuarios
 * @param {string} total Cantidad total de usuarios
 * @function UsersTable
 */
const UsersTable = ({ users, total, dataFilter, segment }) => {

    const [page, setPage] = useState(0);
    // Header para listado de usuarios
    const headers = [
        {
            "name": "NOMBRE USUARIO",
            "sort_name": "nameuser",
            "width": "17%"
        },
        {
            "name": "CORREO ELECTRÓNICO",
            "sort_name": "email",
            "width": "25%"
        },
        {
            "name": "FECHA INSCRIPCIÓN",
            "sort_name": "date",
            "width": "18%"
        },
        {
            "name": "SEGMENTACIÓN",
            "sort_name": "segment",
            "width": "20%"
        },
        {
            "name": "ÚLTIMA SESIÓN",
            "sort_name": "lastlogin",
            "width": "15%"
        }
    ];

    const [usersSorted, setUsersSorted] = useState(users)
    const [totalSorted, setTotalSorted] = useState(total)
    const [userHovered, setUserHovered] = useState(Array(users.length).fill(false))

    useEffect(() => {
        setUsersSorted(users)
        setTotalSorted(total)
    }, [users, total])

    const resetState = () => {
        setUserHovered(Array(users.length).fill(false))
    }

    const handleActiveRow = (index) => {
        let auxArray = Array(users.length).fill(false);
        auxArray[index] = true
        setUserHovered(auxArray)
    }

    const [data, handleFetch] = useFetch();
    const { authToken } = useAuthToken(true);
    // Handle para paginación
    const handlePageClick = (event) => {
        let result = 50 * event.selected;
        setPage(event.selected)
        let filtername = dataFilter.name !== '' ? `&name=` + dataFilter.name : ''
        let filterlastname = dataFilter.lastname !== '' ? "&lastname=" + dataFilter.lastname : ''
        let filteremail = dataFilter.email !== '' ? `&email=` + dataFilter.email : ''
        let filterdocument = dataFilter.document !== '' ? "&document=" + dataFilter.document : ''
        let filtertype = dataFilter.type !== '' ? "&type=" + dataFilter.type : ''
        let filteruntil = dataFilter.until !== '' ? "&until=" + dataFilter.until : ''
        let filtersince = dataFilter.since !== '' ? "&since=" + dataFilter.since : ''
        let filtercompany = "&companyId=" + apiConfig.company
        const response = handleFetch({
            url: `bo/webusers?${filtername}${filterlastname}${filteremail}${filterdocument}${filtertype}${filteruntil}${filtersince}${filtercompany}&$skip=${result}`,
            method: 'GET',
            headers: { Authorization: `Bearer ${authToken}` }
        });
        response
            .then((r) => {
                setUsersSorted(r.data)
                setTotalSorted(r.total)
            },
                error => {
                    console.log(error.errors);
                    if (error.errors) {

                    }
                })
    };
    return (
        <>
            <table className='UserAdmin__table' >
                <thead className='UserAdmin__table-header' style={{ height: "52px" }}>
                    <tr style={{ cursor: "pointer" }}>
                        {headers.map((header, index) => {
                            return (
                                <th key={index} className="overflow" style={{ width: header.width, paddingLeft: "16px" }}>{header.name}</th>
                            )
                        })}
                        <th style={{ width: "13%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        usersSorted.length != 0 ?
                            usersSorted.map((user, index) => {
                                const segmentUser = segment.find(
                                    (item) => parseInt(item.type) === parseInt(user?.type),
                                );
                                return (
                                    <tr onMouseLeave={resetState} onMouseEnter={() => { handleActiveRow(index) }} className={`UserAdmin__table-row ${index % 2 === 0 ? "UserAdmin__table-pair-row" : "UserAdmin__table-odd-row"}`} key={index}>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{user.name} {user.lastname}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}><div className="UserAdmin__table-td">{user.email}</div></td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{new Date(user.createdAt).toISOString().split('T')[0]}</td>
                                        <td className="overflow" style={{ paddingLeft: "16px" }}>{segmentUser?.name}</td>
                                        <td className="overflow" style={{ textAlign: "center" }}>{user.lastLogin ? new Date(user.lastLogin).toISOString().split('T')[0] : ' - '}</td>
                                        {
                                            userHovered[index] ?
                                                <td>
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='UserAdmin__button-container' >
                                                            <Link to={{
                                                                pathname: `/admin/usuarios/detalles`
                                                            }} state={{user, segment: segmentUser}}>
                                                                <i className='fa-solid fa-pen UserAdmin__color-primary'></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td></td>
                                        }
                                    </tr>
                                );
                            })
                            :
                            <tr>
                                <td colSpan="6" style={{ padding: "16px", textAlign: "center" }}><strong>No se encontraron registros</strong></td>
                            </tr>
                    }
                </tbody>
            </table>

            <Pagination
                itemsPerPage={50}
                totalElements={total}
                onChangePage={handlePageClick}
                forcePage={page}
            />
            <div className='mb-3'></div>
        </>
    );
}

/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
    const loadingImage = landingImages('./icons/loading_spin_ean.svg');
    return (
        <div className='d-flex justify-content-center' style={{ minHeight: "300px" }}>
            <div className='row text-center position-absolute justify-content-center'>
                <img alt='Loader' src={loadingImage} />
            </div>
        </div>
    );
}