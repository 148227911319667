import React, { useEffect, useState, Suspense  } from 'react'
import { LandingHeader } from './LandingHeader'
import { SimpleFooter } from '../footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useUserPlan } from '../../hooks/useUserPlan';
import { PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR } from '../../assets/constants';
import { AcceptDialog } from "../controls/UIControls";
const landingImages = require.context('../../assets/images', true);

export const LandingPage = () => {
    const navigate = useNavigate();
    const [eanuserDialog,setEanuserDialog] = useState(false);
    const {authToken, isLogged} = useAuthToken();
    const {checkUserPlan} = useUserPlan();
    const [eanActivePlan, setEanActivePlan] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        if(isLogged()) {
            checkUserPlan(authToken)
            .then (hasPlan => {
             if(hasPlan && hasPlan.planType==PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
                     setEanActivePlan(true);
                 }
            });
           }
           }, []);

    const showEanDialog = e => {
        e.preventDefault();
        setEanuserDialog(true);
    }
    window.addEventListener('scroll', function(){
        if(this.window.scrollY>100){
            const elements = this.document.getElementsByClassName('landing__whatsapp-button-static')
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                element.classList.add("landing__whatsapp-button")
                element.classList.remove("landing__whatsapp-button-static")
            }
        }
    });
    return (
    <>
        <LandingHeader />
        <AcceptDialog 
          open={eanuserDialog}
          message="Ya disfrutas de un plan B2B."
          style='info'
          afterClose={()=>setEanuserDialog(false)}
            ></AcceptDialog>
        <div className='landing__container-eres-comunidad-eanista mt-5'>
            <div className='container me-lg-0 my-5 ms-lg-auto'>
                <div className='row justify-content-lg-end align-items-center'>
                    <div className='col-12 d-block d-lg-none' style={{overflow: "hidden", textAlign: "center", margin: "0 auto"}}>
                        {/* <img alt='Men looking at laptp' src={landingImages('./men-laptop-medium.png')} style={{width: "60%", height: "auto",minWidth: "100%", maxWidth: "none", margin: "0 -100%"}}/> */}
                        <img alt='Men looking at laptp' src={landingImages('./placeholder-home.png')} className="img-fluid" width="516" height="348" />
                    </div>
                    <div className='col-xl-4 col-lg-5 col-md-10 col-12'>
                        <div className='row'>
                            <div className='col-12 landing__text-eres-comunidad-eanista'><span className='landing__text-color-green'>Elige el curso que más te llame la atención</span>
                            </div>
                        </div>
                        <div className='row my-4'>
                            <div className='col-12 landing__paragraph-text'>
                            <p className='landing__text-color-dark-green'>El 24 de enero se celebra el día de la Educación y en EanX te ofrecemos una selección de cursos completamente gratis, solo por tiempo limitado.  </p>

                            <p className='landing__text-color-dark-green fw-bold'>¡Aprovecha esta oportunidad para sumergirte en nuevos conocimientos y habilidades!</p>
                            <p className='landing__text-color-dark-green'>Ten en cuenta que tienes 8 días para finalizar el curso de tu elección y obtener tu certificación. </p>
                            <p className='landing__text-eres-comunidad-eanista_bold'>¡Feliz día de la Educación!</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 col-xl-8 d-flex justify-content-end pe-0 d-none d-lg-grid'>
                        {/* <img alt='Men looking at laptp' src={landingImages('./men-laptop.png')} style={{width: "100%", height: "auto"}}/> */}
                        <img alt='Men looking at laptp' src={landingImages('./placeholder-home.png')} className="img-fluid" width="822" height="478" />
                    </div>
                </div>
            </div>
        </div>
        <SimpleFooter />
    </>
  )
}
