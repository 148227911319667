// Etiquetas
export const LABEL_NAME_PLACEHOLDER = 'Ingresa tu nombre';
export const LABEL_LASTNAME_PLACEHOLDER = 'Ingresa tu apellido';
export const LABEL_EMAIL_PLACEHOLDER = 'nombre@correo.com';
export const LABEL_DOCUMENT_TYPE_PLACEHOLDER = 'Selecciona tu tipo de documento';
export const LABEL_DOCUMENT_PLACEHOLDER = 'Ingresa el número de documento';
export const LABEL_PHONE_PLACEHOLDER = '999 999 999';
export const LABEL_ADDRESS_PLACEHOLDER = 'Ingresa tu dirección';
export const LABEL_PASSWORD_PLACEHOLDER = 'Ingresa tu contraseña';
export const LABEL_PASSWORD_CONFIRM_PLACEHOLDER = 'Ingresa tu contraseña nuevamente';
export const LABEL_MESSAGE_TEXT_PLACEHOLDER = 'Escribe tu mensaje acá';
export const LABEL_MESSAGE_DISCOUNT = 'Ingresa tu cupón...';

// Etiquetas para admin
export const LABEL_PLAN_TITLE_PLACEHOLDER = 'Ingresa el título del plan';
export const LABEL_PLAN_DESCRIPTION_PLACEHOLDER = 'Ingresa la descripción del plan';
export const LABEL_PLAN_DURATION_PLACEHOLDER = 'Ingresa la descripción del plan';
export const LABEL_PLAN_DURATION_TEXT_PLACEHOLDER = 'Ingresa la descripción';
export const LABEL_PLAN_PRICE_PLACEHOLDER = 'Ingresa el costo a cobrar';
export const LABEL_CERTIFICATIONS_PLACEHOLDER = 'Ingresa el número de certificaciones';
export const LABEL_PLAN_COMPARED_PRICE_PLACEHOLDER = 'Ingresa el costo comparativo (aparece tachado)';
export const LABEL_PLAN_ACTION_LABEL_PLACEHOLDER = 'Ingresa el texto del botón de acción';


// Mensajes
export const MESSAGE_GENERIC_ERROR = 'Error de comunicación. Verifica tu conexión a internet e intenta nuevamente.';

export const MESSAGE_NAME_REQUIRED = 'Es necesario ingresar un nombre';

export const MESSAGE_LASTNAME_REQUIRED = 'Es necesario ingresar un apellido';

export const MESSAGE_EMAIL_REQUIRED = 'Es necesario ingresar un correo';
export const MESSAGE_EMAIL_INVALID = 'Debes ingresar un correo válido';
export const MESSAGE_EMAIL_REGISTERED = 'Tu correo ya está registrado';

export const MESSAGE_DOCUMENT_TYPE_REQUIRED = 'Es necesario seleccionar un tipo de documento';

export const MESSAGE_DOCUMENT_REQUIRED = 'Es necesario ingresar un número de documento';
export const MESSAGE_DOCUMENT_NUMERIC_INVALID = 'Escribe solo números (no incluyas puntos, comas o guiones)';
export const MESSAGE_DOCUMENT_ALPHANUMERIC_INVALID = 'Escribe solo números y letras (no incluyas puntos, comas o guiones)';
export const MESSAGE_ONLY_ALPHABET = 'Escribe solo letras (no incluyas puntos, comas o guiones)';
export const MESSAGE_DOCUMENT_REGISTERED = 'Tu documento ya registrado al correo %1';

export const MESSAGE_OLDER_THAN_12_REQUIRED = 'Debes validar que eres mayor de 12 años';

export const MESSAGE_PHONE_REQUIRED = 'Es necesario ingresar un número de contacto';

export const MESSAGE_ADDRESS_REQUIRED = 'Es necesario ingresar una dirección';

export const MESSAGE_PASSWORD_REQUIRED = 'La contraseña no puede ser vacía';
export const MESSAGE_PASSWORD_IVALID = 'Esta contraseña no cumple con los requisitos';

export const MESSAGE_CHANGE_PASSWORD_INVALID = 'Token invalido';

export const MESSAGE_PASSWORD_CONFIRM_REQUIRED = 'La contraseña no puede ser vacía';
export const MESSAGE_PASSWORD_CONFIRM_DOESNT_MATCH = 'Parece que tus contraseñas no coinciden';

export const MESSAGE_ACCEPT_TERMS_REQUIRED = 'Debes aceptar los términos y condiciones para registrarte';

export const MESSAGE_ACCEPT_POLICY_REQUIRED = 'Debes aceptar la política de tratamiento de datos para registrarte';
export const MESSAGE_ACCEPT_HABEAS_DATA_POLICY = 'Debes aceptar la política de habeas data.';

export const MESSAGE_CODE_INVALID = 'Código de verificación no válido';
export const MESSAGE_CODE_EXPIRED = 'Código de verificación vencido';
export const MESSAGE_USER_VERIFIED = 'Tu usuario ya está verificado';

export const MESSAGE_USER_INVALID = 'Combinación email/contraseña no válida';
export const MESSAGE_USER_UNVERIFIED = 'Dirección no verificada';
export const MESSAGE_USER_BLOCKED = 'Tu usuario ha sido bloqueado. Para desbloquear tu cuenta, restablece tu contraseña';

export const MESSAGE_RECOVERY_SENT = `Enviamos un enlace al correo "%1" para que restablezcas tu contraseña`;

export const MESSAGE_TEXT_REQUIRED = 'Es necesario ingresar un mensaje';
export const MESSAGE_SUBJECT_REQUIRED = 'Es necesario ingresar un asunto';


export const MESSAGE_PLAN_TITLE_REQUIRED = 'El título es requerido';
export const MESSAGE_PLAN_DESCRIPTION_REQUIRED = 'La descripción es requerida';
export const MESSAGE_PLAN_DURATION_REQUIRED = 'La duración es requerida';
export const MESSAGE_PLAN_DURATION_TEXT_REQUIRED = 'La duración en texto es requerida';
export const MESSAGE_PLAN_PRICE_REQUIRED = 'El precio es requerido';
export const MESSAGE_CERTIFICATIONS_REQUIRED = 'El número de certificaciones es requerido';
export const MESSAGE_PLAN_ACTION_LABEL_REQUIRED = 'El texto del botón es requerido';

export const MESSAGE_PLAN_PRICE_INVALID = 'Debes ingresar un precio válido';
export const MESSAGE_CERTIFICATIONS_INVALID = 'Debes ingresar un número de certificaciones válido';


export const MESSAGE_PLAN_ERROR = 'Ocurrió un error al guardar el plan. Intenta nuevamente.';


export const LABEL_USERNAME_PLACEHOLDER = 'Ingresa un nombre de usuario';
export const MESSAGE_USERNAME_REQUIRED = 'El nombre de usuario es requerido';
export const LABEL_TELEPHONE_PLACEHOLDER = 'Ingresa tu número de teléfono';
export const MESSAGE_TELEPHONE_REQUIRED = 'El número de teléfono es requerido';
export const LABEL_COURSE_PLACEHOLDER = 'Escribe al menos un curso';
export const MESSAGE_COURSE_REQUIRED = 'Debe tener inscrito al menos un curso requerido';

// Constantes
export const AUTH_TOKEN_KEY = 'eanx-auth-token';
export const ADMIN_AUTH_TOKEN_KEY = 'eanx-auth-token-admin';
export const USER_INFO = "user";
export const ADMIN_USER_INFO = "user-admin";

export const USER_HAS_PLAN_KEY = "user-has-plan";

export const STICKY_FIRST_SHOW = 30;
export const STICKY_SECOND_SHOW = 15;
export const STICKY_DAILY_SHOW = 7;

export const STICKY_FIRST_SHOW_KEY = `sticky_shown_${STICKY_FIRST_SHOW}`;
export const STICKY_SECOND_SHOW_KEY = `sticky_shown_${STICKY_SECOND_SHOW}`;

export const STICKY_CLOSED_KEY = 'sticky_closed';
export const STICKY_SHOW_KEY = 'sticky_show';
export const MAX_MB_LIMIT = 41943040;
export const MAX_FILES_LIMIT = 5;




//patrones
export const PATTERN_ALPHABET = /^([A-Za-zÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞGĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğgģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼMM̄'NŃNṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽmm̄ŉńnṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠŞṢŤŢṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťţṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]+[ ]?)+$/;
export const PATTERN_NUMERIC = /^[0-9]+$/;
export const PATTERN_ALPHANUMERIC = /^([0-9]|[a-zA-Z])+$/;
export const PATTERN_FLOAT = /^[0-9]*\.?[0-9]*$/;
export const PATTERN_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


//Tipos de usuarios
export const USER_TYPE_GENERAL = 1;
export const USER_TYPE_EAN_STUDENT = 2;
export const USER_TYPE_EAN_PAYROLL_TEACHER = 3;
export const USER_TYPE_EAN_SUBJECT_TEACHER = 4;
export const USER_TYPE_EAN_COLLABORATOR = 5;
export const USER_TYPE_EAN_GRADUATE = 6;


//Tipos de planes
export const PLAN_TYPE_PUBLIC = 1;
export const PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR = 2;
export const PLAN_TYPE_EAN_GRADUATE = 3;

//Estado del plan
export const PLAN_STATUS_ACTIVE = 1
export const PLAN_STATUS_PENDING = 2
export const PLAN_STATUS_EXPIRED = 3

//Tipos de usuarios texto
export const USER_GENERAL = 'Público en General';
export const USER_EAN_STUDENT = 'Estudiante eanista';
export const USER_EAN_PAYROLL_TEACHER = 'Docente de Planta';
export const USER_EAN_SUBJECT_TEACHER = 'Docente de Cátedra';
export const USER_EAN_COLLABORATOR = 'Colaborador';
export const USER_EAN_GRADUATE = 'Egresado';