import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useAuthToken } from '../../hooks/useAuthToken';
import { SimpleFooter } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { CatalogSection } from './CatalogSection'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección catálogo
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const CatalogPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLogged }= useAuthToken();
    const logged = isLogged();
    const {user, setUser} = useContext(UserContext);
    const {getSessionUser} = useAuthToken();
    useEffect(()=> {
      if(logged){
        const sessionUser = getSessionUser();
        setUser(sessionUser);
      }else{
        navigate("/ingreso");
      }
    },[]);


    const [searchCriteria, setSearchCriteria] =  useState(location.state?location.state:{});
    const searchAction = (keyword) => {
      setSearchCriteria({keyword});
    }

    return (
    <div>
        <LandingHeader  searchAction={searchAction} />
        <div style={{marginTop: "75px"}}></div>
        <CatalogSection filter={searchCriteria} user={user} />
        <SimpleFooter />
    </div>
  )
}
