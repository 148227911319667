import React from 'react';
import { AdminHeader } from './../plans/AdminHeader';
import { SimpleFooter } from '../../footer/Footer';
import { UserCreateEditSection } from './UserCreateEditSection';
import { useLocation } from 'react-router-dom';

/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar contenido de sección edición de usuarios
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface AdminHeader
 */
export const UserCreateEditPage = () => {
    const location = useLocation();
    const dateUser = location.state?.user;
    const dateSegment = location.state?.segment;

    return (
        <>
            <AdminHeader />
            <div style={{marginTop: "100px"}}></div>
            <UserCreateEditSection user={dateUser} segment={dateSegment} />
            <SimpleFooter />
        </>
    )
}