// Your web app's Firebase configuration
export const apiConfig = {
    // Api Key para conexión
    header: {
        'Content-Type': 'application/json',
        'x-api-key': 'L8vDTV7wJMa6s8BGCsbC8uL7cFGu08DybuQnkwAKjpULZDpFcShAoOOv31Bf'
    },
    company: 16,
    baseUrl: 'https://eanx.co/api/',
    mercadoPago: {
        returnUrl: 'https://livehappy.eanx.co/cuenta-creada'
    }
  };