import React, {useEffect} from 'react'
import { PasswordValidations } from './PasswordValidations';
import '../../styles/components/controls/UIControls.scss';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para validar contraseña
 * @param {props} props Contraseñas para validar igualdad y que se cumplan criterios de validación
 * @function PasswordValidator
 */
export const PasswordValidator = (props) => {
    const testCriteria = (value, pattern) => {
        return (value.match(pattern));
    }
    const validPassword = (value) => {
        const missingCriteria = PasswordValidations.find(criteria => !value.match(criteria.pattern));
        return missingCriteria===undefined;
    } 
    
    useEffect(()=> {
        if(props.checkCriteria && props.value!==undefined) props.checkCriteria(validPassword(props.value));
    });
    

    return (
        <div>
            { PasswordValidations.map((criteria,index) => (
                        <span key={criteria.id} className={
                            `PwdCriteria--${testCriteria(props.value?props.value:'', criteria.pattern)?
                                                'ok':
                                                'error'}`}>{criteria.label}
                            &nbsp;
                        </span>
                ))
            }
        </div>
    )
}