import React, {useEffect, useState} from 'react'
import { Toast, SubmitFormButton } from '../controls/UIControls';
import { useForm as hookUseForm, Controller } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import * as CONSTANTS from '../../assets/constants';
import { useFetch } from '../../hooks/useFetch';
import Modal from '@mui/material/Modal';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken';
const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Sección para formulario de contacto
 * @interface ContactUsSection
 */
export const ContactUsSection = () => {
  
  const { register, handleSubmit, watch, 
    control, formState: { errors }, setError, 
    getValues, setFocus, setValue } = hookUseForm();

    const [data, handleFetch] = useFetch();
    const alphabetPattern = CONSTANTS.PATTERN_ALPHABET;
    const numericPattern = CONSTANTS.PATTERN_NUMERIC;
    const alphanumericPattern = CONSTANTS.PATTERN_ALPHANUMERIC;

    const numericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const alphanumericMessage=CONSTANTS.MESSAGE_DOCUMENT_NUMERIC_INVALID;
    const [namePattern, setNamePattern] = useState(alphabetPattern);
    const [docPattern, setDocPattern] = useState(numericPattern);
    const [docMessage, setDocMessage] = useState(numericMessage);
    const [showAcceptHabeasData, setShowAcceptHabeasData] = useState(false);
    const [showAge, setShowAge] = useState(false);
    const [showModalMailSending, setShowModalMailSending] = useState(false);
    const [showModalMailSendingError, setShowModalMailSendingError] = useState(false);
    // Handle cerrar modal cuando se envía correo electrónico
    const handleCloseModal = () => {
        setShowModalMailSending(false);
        navigate('/');
    }
    // Handle cerrar modal cuando se envía correo electrónico en caso de error
    const handleCloseModalError = () => {
        setShowModalMailSendingError(false);
    }
    const [dialogProperties, setDialogProperties] = useState({open:false});
    const navigate = useNavigate();
    const location = useLocation();
    const eanuser = location?.state ? location?.state.eanuser : {};
    const { getSessionUser } = useAuthToken();
    const user = getSessionUser();
    const [toastProperties, setToastProperties] = useState({show: false});
    const afterCloseToast = () => {
        setToastProperties({show:false});
    }
    useEffect(() => {
        // Si es usuario eanista muestra asunto y mensaje predeterminado
        if(location){
            setValue("subject", eanuser?.subject);
            setValue("message", eanuser?.message);
        }
        // Si usuario está logueado, se carga su información registrada
        if(user){
            setValue("firstName", user?.name);
            setValue("lastName", user?.lastname);
            setValue("userEmail", user?.email);
            setValue("documentType", user?.documentType.toUpperCase());
            setValue("userDocument", user?.document);
            setValue("phone", user?.phone);
        }
    }, []);
    // Función para enviar datos de formulario de contacto
    const  sendContactRequest =  (async (formValues)=>{
        let notify = false;
        
        if(formValues.subject=='Habeas Data y Corrección de Datos'){
            notify = true;
        }
        let documentTypeValue = ''
        switch (formValues.documentType) {
            case "CC":
                documentTypeValue = 1
                break;
            case "CE":
                documentTypeValue = 2
                break;
            case "DIE":
                documentTypeValue = 3
                break;
            case "PA":
                documentTypeValue = 4
                break;
            case "TI":
                documentTypeValue = 5
                break;
            default:
                documentTypeValue = 1
                break;
        }
        let subTypeCaseValue = ''
        switch (formValues.subject) {
            case "Planes y Pagos":
                subTypeCaseValue = 1
                break;
            case "Mi Cuenta EanX":
                subTypeCaseValue = 2
                break;
            case "Cursos y Certificaciones":
                subTypeCaseValue = 3
                break;
            case "Soporte Tecnológico":
                subTypeCaseValue = 4
                break;
            case "Habeas Data y Corrección de Datos":
                subTypeCaseValue = 5
                break;
            case "Soy Comunidad Eanista":
                subTypeCaseValue = 6
                break;
            case "Otros":
                subTypeCaseValue = 7
                break;
            default:
                subTypeCaseValue = 7
                break;
        }
        const files = []
        files.push(formValues.filesupport)
        let attachments = await attachmentFiles(files)
        console.log("attachments",attachments)
        var data = {
            hash: 'eeb6a0acd382271c181337a91de5487c',
            nombre: formValues.firstName,
            apellido: formValues.lastName,
            tipoDocumento: documentTypeValue.toString(),
            numeroDocumento: formValues.userDocument,
            correo: formValues.userEmail,
            detalleMedioRespuesta: formValues.userEmail,
            telefono: formValues.phone.replace('+', ''),
            celular: formValues.phone.replace('+', ''),
            asunto: formValues.subject,
            descripcion: formValues.message,
            tipoCliente: 'Cliente',
            origenCaso: '12',
            areaCaso: '1',
            tipoCaso: '1',
            subTipoCaso: subTypeCaseValue,
            mediosRespuestaCaso: '5',
            CheckCaseExist: 'true',
            adjuntos: JSON.stringify(attachments),
        }
        let totalSize = testFile(files)
        if(totalSize <= CONSTANTS.MAX_MB_LIMIT && attachments.length < CONSTANTS.MAX_FILES_LIMIT){
            const response = handleFetch({
                url:'contactus',
                method: 'POST',
                data,
            });
            response
                .then((r) => {
                        setShowModalMailSending(true);
                    },
                    error => {
                        setShowModalMailSendingError(true);
                    })
        }else{
            let errormessage = (attachments.length > 5) ? 'Puedes adjuntar máximo 5 archivos' : 'Uno o varios archivos exceden el límite de tamaño, revísalos'
            setToastProperties({
                show:true,
                type:'error',
                title: 'Los archivos exceden el máximo permitido',
                message: errormessage,
                position: {horizontal:'left'},
            })
        }

    });
    // Función para calcular tamaño de los archivos
    const testFile = (file) => {
        let totalSize = 0;
        for (let i = 0; i < file[0].length; i++) {
            totalSize += file[0][i].size
        }
        return totalSize;
    };
    // Función para adjuntar archivos
    const attachmentFiles = (files) => {
        
        let attachments = []
        for (let i = 0; i < files[0].length; i++) {
            attachments.push(new Promise(resolve => {
                let reader = new FileReader();
                reader.readAsDataURL(files[0][i]);
                reader.onloadend = () => {
                    resolve({name: files[0][i].name, extension: files[0][i].name.substr(-4), adjunto: reader.result})
                };
            }))
        }
        return Promise.all(attachments)
    };
    // Función para selección de tipo de documento
    const handleSelectChange = (selectedOption) => {
        if(selectedOption.target.value === 'DIE' || selectedOption.target.value === 'PA' || selectedOption.target.value === 'TI') {
            setShowAge(true);
        }else {
            setShowAge(false);
        }
        if(selectedOption.target.value === 'PA') {
            setDocPattern(alphanumericPattern);
            setDocMessage(alphanumericMessage);
        } else {
            setDocPattern(numericPattern);
            setDocMessage(numericMessage);
        }
    }
    // Función para caso Habeas data
    const handleSelectSubject = (selectedOption) => {
        
        if(selectedOption.target.value==='Habeas Data y Corrección de Datos'){
            setShowAcceptHabeasData(true)
        }else{
            setShowAcceptHabeasData(false)
        }
    
    }
  return (

    <>
        <Toast {...toastProperties} closeCallback={afterCloseToast} position={{vertical:'bottom', horizontal: 'left'}} ></Toast>
        <ModalMailSending handleCloseModal={handleCloseModal} showModalMailSending={showModalMailSending}/>
        <ModalMailSendingError handleCloseModal={handleCloseModalError} showModalMailSendingError={showModalMailSendingError}/>
    <div>
      <img alt='banner' className="d-lg-block d-none position-absolute"  src={landingImages('./contact-us-background.jpg')} width="100%"  />
      <img alt='banner' className="d-none d-md-block d-lg-none position-absolute"  src={landingImages('./contact-us-background-tablet.jpg')} width="100%"  />
      <img alt='banner' className="d-block d-md-none d-lg-none position-absolute"  src={landingImages('./contact-us-background-phone.jpg')} width="100%" />

      <div className="ContactUs__form-title-container">
        <div className="d-flex justify-content-center">
          <h1 className="ContactUs__title">Contáctanos</h1>
        </div>
        <div className="d-flex justify-content-center">
          <div className="ContactUs__box-container ContactUs__box-container--rounded-5">
            <div className="ContactUs__salutation">
              Solucionar tu situación es  
              {
                  window.innerWidth<992
                  ?
                  <br></br>
                  :<> </>
              }
               muy importante para nosotros,
               {
                  window.innerWidth<992
                  ?
                  <br></br>
                  :<> </>
              }
               cuéntanos tu caso:
            </div>

            <form onSubmit={handleSubmit(sendContactRequest)}>

                <div className="mb-3">
                    <label className='auth__form-label'>Asunto <span className='auth__required-input'>*</span></label>
                    <select 
                        defaultValue=""
                        className={`form-select auth__select 
                                    ${errors.subject?"auth__input--error":""}`}
                        aria-label="Default select example"
                        name='subject'
                        
                        {...register("subject", { required: CONSTANTS.MESSAGE_SUBJECT_REQUIRED,
                                                  onChange: handleSelectSubject })}
                    >
                        <option value="">Seleccionar texto...</option>
                        <option value="Planes y Pagos">Planes y Pagos</option>
                        <option value="Mi Cuenta EanX">Mi Cuenta EanX</option>
                        <option value="Cursos y Certificaciones">Cursos y Certificaciones</option>
                        <option value="Soporte Tecnológico">Soporte Tecnológico</option>
                        <option value="Habeas Data y Corrección de Datos">Habeas Data y Corrección de Datos</option>
                        <option value="Soy Comunidad Eanista">Soy Comunidad Eanista</option>
                        <option value="Soy Menor de 12 Años">Soy Menor de 12 Años</option>
                        <option value="Otros">Otros</option>
                    </select>
                    {errors.subject && (
                        <span role="alert" className='auth__inputError'>
                        {errors.subject.message}
                        </span>
                    )}                        
                </div>
                <div className="mb-3">
                    <label className='auth__form-label'>Mensaje <span className='auth__required-input'>*</span></label>
                    <textarea 
                        name='message'
                        className={`form-control auth__input 
                                    ${errors.text?"auth__input--error":""}`}
                        placeholder={CONSTANTS.LABEL_MESSAGE_TEXT_PLACEHOLDER}
                        {...register("message", { required: CONSTANTS.MESSAGE_TEXT_REQUIRED })}
                        style={{height: "129px"}}
                    />
                    {errors.message && (
                        <span role="alert" className='auth__inputError'>
                        {errors.message.message}
                        </span>
                    )}                         
                </div>
                
                <div className="mb-3">
                    <label className='auth__form-label'>Nombres <span className='auth__required-input'>*</span></label>
                    <input 
                        type='text' 
                        name='firstName'
                        className={`form-control auth__input 
                                    ${errors.firstName?"auth__input--error":""}`}
                        placeholder={CONSTANTS.LABEL_NAME_PLACEHOLDER}
                        {...register("firstName", { required: CONSTANTS.MESSAGE_NAME_REQUIRED,
                            pattern: {
                                value: namePattern,
                                message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                            } })}
                    />
                    {errors.firstName && (
                        <span role="alert" className='auth__inputError'>
                        {errors.firstName.message}
                        </span>
                    )}                         
                </div>
                
                <div className="mb-3">
                    <label className='auth__form-label'>Apellidos <span className='auth__required-input'>*</span></label>
                    <input 
                        type='text' 
                        name='lastName'
                        className={`form-control auth__input 
                                    ${errors.lastName?"auth__input--error":""}`}
                        placeholder={CONSTANTS.LABEL_LASTNAME_PLACEHOLDER}
                        {...register("lastName", { required: CONSTANTS.MESSAGE_LASTNAME_REQUIRED,
                            pattern: {
                                value: namePattern,
                                message: CONSTANTS.MESSAGE_ONLY_ALPHABET,
                            } })}
                    />
                    {errors.lastName && (
                        <span role="alert" className='auth__inputError'>
                        {errors.lastName.message}
                        </span>
                    )}                        
                </div>
                <div className="mb-3">
                    <label className='auth__form-label'>Correo <span className='auth__required-input'>*</span></label>
                    <input 
                        type='text' 
                        name='userEmail'
                        className={`form-control auth__input 
                                    ${errors.userEmail?"auth__input--error":""}`}
                        placeholder={CONSTANTS.LABEL_EMAIL_PLACEHOLDER}
                        {...register("userEmail", { required: CONSTANTS.MESSAGE_EMAIL_REQUIRED,
                                                    pattern: {
                                                        value:  CONSTANTS.PATTERN_EMAIL ,
                                                        message:  CONSTANTS.MESSAGE_EMAIL_INVALID 
                                                    }})}
                    />
                    {errors.userEmail && (
                        <span role="alert" className='auth__inputError'>
                        {errors.userEmail.message}
                        </span>
                    )}                        
                </div>
                
                <div className="mb-3">
                    <label className='auth__form-label'>Tipo de documento </label>
                    <select 
                        defaultValue={user?user.documentType:''}

                        className={`form-select auth__select 
                                    ${errors.documentType?"auth__input--error":""}`}
                        aria-label="Default select example"
                        name='documentType'
                        
                        {...register("documentType", { required: CONSTANTS.MESSAGE_DOCUMENT_TYPE_REQUIRED,
                                                        onChange: handleSelectChange  })}
                    >
                        <option value="">Selecciona el tipo de documento</option>
                        <option value="CC">Cédula de Ciudadanía</option>
                        <option value="CE">Cédula de Extranjería</option>
                        <option value="DIE">Documento de Identidad Extranjera</option>
                        <option value="PA">Pasaporte</option>
                        <option value="TI">Tarjeta de Identidad</option>
                    </select>
                    {errors.documentType && (
                        <span role="alert" className='auth__inputError'>
                        {errors.documentType.message}
                        </span>
                    )}                        
                </div>
                
                {
                    showAge ? (
                        <div className='show-form-age'>
                            <div className="form-check">
                                <input className="form-check-input auth__form-check"
                                       type="checkbox"
                                       value=""
                                       {...register("olderThan12", {required: CONSTANTS.MESSAGE_OLDER_THAN_12_REQUIRED})}
                                />
                                <label className='form-check-label auth__form-label'  style={{fontSize: "12px"}}>
                                    Declaro ser mayor de 12 años.
                                </label>
                            </div>
                            <div>
                                <label className='form-check-label auth__form-label' style={{fontSize: "12px", paddingBottom: "12px"}}>
                                    Si eres menor de 12 años requieres autorización expresa de tus padres o tutores,
                                    pídeles que se comuniquen con nosotros <Link to='/contactanos' className='text_primary'>aquí.</Link>
                                </label>
                            </div>
                            {errors.olderThan12 && (
                                <div role="alert" className='auth__inputError'>
                                    {errors.olderThan12.message}
                                </div>
                            )}
                        </div>
                    ) : null
                }
                <div className="mb-3">
                    <label className='auth__form-label'>Número de documento <span className='auth__required-input'>*</span></label>
                    <input 
                        type='text' 
                        name='userDocument'
                        className={`form-control auth__input 
                                    ${errors.userDocument?"auth__input--error":""}`}
                        placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                        {...register("userDocument", { 
                            required: CONSTANTS.MESSAGE_DOCUMENT_REQUIRED, 
                            pattern: {
                                value: docPattern,
                                message: docMessage
                            }
                            })}
                    />
                    {errors.userDocument && (
                        <span role="alert" className='auth__inputError'>
                        {errors.userDocument.message}
                        </span>
                    )}                        
                </div>
                <div className="mb-3">
                    <label className='auth__form-label'>Teléfono <span className='auth__required-input'>*</span></label>
                    <Controller
                        name="phone"
                        control={control}
                        className="auth__select"
                        rules={{ required: CONSTANTS.MESSAGE_PHONE_REQUIRED }}
                        render={({ field }) => 
                        <PhoneInput {...field}
                        inputClass={`auth__input-phone 
                                    ${errors.phone?"auth__input--error":""}`}
                        specialLabel=''
                        inputProps={{
                            name: 'phone',
                            required: true,
                            placeholder: CONSTANTS.LABEL_PHONE_PLACEHOLDER
                        }}
                        country={'co'}/>}
                    />
                    {errors.phone && (
                        <span role="alert" className='auth__inputError'>
                        {errors.phone.message}
                        </span>
                    )}                        
                </div>
                <div className="mb-3">
                    <label className='auth__form-label'>Adjuntar Archivos</label>
                    <input
                        type='file'
                        name='filesupport'
                        accept='.doc, .docx, .pdf, image/png, image/jpeg'
                        multiple={true}
                        className={`form-control auth__input`}
                        placeholder={CONSTANTS.LABEL_DOCUMENT_PLACEHOLDER}
                        {...register("filesupport")}
                    />
                    <span className='auth__comment' style={{fontSize: "12px"}}>Tamaño máximo permitido 40MB</span>
                </div>
                <div className="form-check">
                    <input className="form-check-input auth__form-check" 
                        type="checkbox" 
                        value="" 
                        {...register("acceptPolicy", {required:CONSTANTS.MESSAGE_ACCEPT_POLICY_REQUIRED})}
                        />
                    <label className='form-check-label auth__form-label' style={{fontSize: "12px"}}>
                    Tengo a disposición la <a href='https://universidadean.edu.co/sites/default/files/institucion/acuerdos/politica-tratamiento-de-datos-personales.pdf' className='auth__link'>Política de tratamiento de datos personales </a> 
                    y el <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/aviso-de-privacidad' className='auth__link'>Avíso de privacidad</a> en la Universidad Ean 
                    y confundamento en ello, <a href='https://universidadean.edu.co/la-universidad/quienes-somos/orientacion-estrategica/reglamentos-universidad-ean/autorizacion-para-uso-de-datos-personales' className='auth__link'>autorizo</a> a la Universidad Ean de manera voluntaria, explícita, informada e inequivoca el 
                    tratamiento de mis datos personales.
                    </label>
                </div>
                {
                    showAcceptHabeasData
                    ?
                        <div className="form-check">
                            <input className="form-check-input auth__form-check" 
                                type="checkbox" 
                                value="" 
                                {...register("acceptHabeasData", {required:CONSTANTS.MESSAGE_ACCEPT_HABEAS_DATA_POLICY})}
                                />
                            <label className='form-check-label auth__form-label' style={{fontSize: "12px"}}>
                                Recuerda que la solicitud de eliminación, supresión o corrección de datos que solicites mediante esta página, solo tendrá efectos en la base de datos de EanX.
                            </label>
                        </div>
                    :
                        null
                }
                {errors.acceptPolicy && (
                    <div role="alert" className='auth__inputError'>
                        {errors.acceptPolicy.message}
                    </div>
                )}
                <button
                    type="submit"
                    className={`ContactUs__btn-form`}
                    disabled={data.loading}
                >
                    {!data.loading && (<span>Enviar</span>)}
                    {data.loading && (<><span> Procesando... </span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) }
                </button>
                {/* <SubmitFormButton main_title="Enviar" wait_title="Procesando..." loading={data.loading}></SubmitFormButton> */}
            </form>
          </div>
        </div>
      </div>




    </div></>
      // <AuthFormContainerPage  content={contactForm} containerClass='py-5'></AuthFormContainerPage>

  )
}
// Función para mostrar mensaje exitoso en Modal de envío de solicitud
const ModalMailSending = ({showModalMailSending, handleCloseModal}) =>{

    return(
        <Modal
            open={showModalMailSending}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./icons/Nuestros-Expertos.png')} style={{width: "65px", marginTop: "25px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Solicitud enviada</div>
                        <p className='ProfilePage__modal-text-description'>
                            Hemos recibido tu solicitud y estamos trabajando en ella, en cuanto tengamos una respuesta, nos comunicaremos contigo. No olvides revisar tu correo electrónico.
                        </p>
                        <div style={{marginTop: "10px", marginBottom: "30px",textAlign: "center"}}>
                            <button
                                className='ProfilePage__modal-button-accept'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
// Función para mostrar mensaje de error en Modal de envío de solicitud
const ModalMailSendingError = ({showModalMailSendingError, handleCloseModal}) =>{

    return(
        <Modal
            open={showModalMailSendingError}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='ProfilePage__modal-base ProfilePage__outline-none'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-4 text-center'>
                        <img src={landingImages('./error.png')} style={{width: "65px", marginTop: "25px"}} alt="padlock" />
                    </div>
                    <div className='ProfilePage__modal-info-container'>
                        <div className='ProfilePage__modal-title'>Error al enviar</div>
                        <p className='ProfilePage__modal-text-description'>
                            Ha ocurrido un error al tratar de enviar tu información, por favor intenta nuevamente.
                        </p>
                        <div style={{marginTop: "10px", marginBottom: "30px",textAlign: "center"}}>
                            <button
                                className='ProfilePage__modal-button-accept'
                                onClick={handleCloseModal}
                                style={{marginLeft: "0px"}}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
