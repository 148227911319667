import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { RegisterPage } from '../register/RegisterPage';
import { RegisterWithPaymentPage } from '../registerwithpayment/RegisterWithPaymentPage';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para seleccionar tipo de registro (Gratuito/Pago)
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface NotFoundPage
 */
export const SelectRegister = () => {
    const location = useLocation();
    const navigator = useNavigate();
    const plan = location?.state?.plan;
    const userID = location?.state?.user.id;
    useEffect(()=> {
        if(plan == undefined || plan == null){
            navigator('/') ;
        }
    },[]);

    return (
        <div>
             { plan.price === 0 ? <RegisterPage userID={userID} /> : <RegisterWithPaymentPage userID={userID} /> }
        </div>
    )
}