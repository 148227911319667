import React from 'react';
import { Link } from 'react-router-dom';
import { AuthFormContainerPage } from '../controls/UIControls';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para confirmar que password fue cambiado correctamente
 * @interface SuccessfulKeyChangePage
 */
export const SuccessfulKeyChangePage = () => {
    const properties = {
        title: "Tu clave ha sido cambiada exitosamente",
        header: (<div>
                    <img 
                        alt='Ean logo' 
                        src={landingImages('./check.png')} 
                        style={{width: "100px", height: "auto"}} 
                        className="mx-auto d-block auth__img-check mb-3"
                    />
                </div>),
        content: (<Link to="/ingreso" replace >
                    <button className="auth__btn-form">Ir al login</button>
                    </Link>
                    )
    }
    return <AuthFormContainerPage {...properties}></AuthFormContainerPage>
}
