import React, { useEffect } from 'react';
import { Chart } from "react-google-charts";
import './styles.scss'; 
export const options = {
  height: 400,
  backgroundColor: 'transparent'
};

export function BarGoogleCharts({data}) {
  const tData = data && data.length !==0 && [
    ["Compania B2B", "Usuarios"],
    ...data.map(item => [item.name, parseInt(item.value)])
  ];
  return (
    tData && <div className="chart-container"><Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={tData}
      options={options}
    /></div>
  );
}