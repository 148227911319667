import { useState } from "react";
import { useFetch } from "./useFetch";
import { apiConfig } from '../api/apiConfig';
import { useUserPlan } from "./useUserPlan";

// Hook para gestionar planes
export const useGetPlan = () => {
    const [data, handleFetch] = useFetch();
    const [mpPreference, setMpPreference] = useState('null');
    const {saveUserHasPlan} = useUserPlan();
    
    // Handle envío de información a mercagopago
    const mpPayment = (data, recurring=false) => {
        const action = recurring?'subscription':'preference'       
        handleFetch({
          url: `payments/mp_${action}`,
          method: 'POST',
          data
        }).then(p => {
            window.location.replace(p.init_point);
        })
    }
    // Handle para asignar plan a usuario, recibe por parámetro id de usuario, id de plan y código si completa operación por mercadopago
    const associatePlan = (user,plan,code=null) => {
        return new Promise((resolve) => {
            let planAssociated='assigned';
            handleFetch({
                url: 'users/plans',
                method: 'POST',
                data: {
                    userId: user.id,
                    planId: plan.id,
                    approvalCode: code,
                }
            })
            .then (plan => {
                saveUserHasPlan(plan);
            })
            .catch(e => {planAssociated='not_assigned'})
            .finally (() => {
                resolve(planAssociated);
            })
        })
    }
    // Función para asociar plan según condición, recibe id usuario, id plan y pago recurrente
    const getPlan =  (user, plan, recurrent, fromRegister) => {
        let today = new Date();
        let day = today.getDate()<10 ? `0${today.getDate()}` : today.getDate();
        let month = today.getMonth()<10 ? `0${today.getMonth()+1}` : today.getMonth() + 1;
        let year = today.getFullYear();console.log(`${month}/${day}/${year}`);
        return new Promise((resolve) => {
            if(plan.price==0) {
                associatePlan(user,plan,`Plan gratis ${day}/${month}/${year}`)
                .then(pa => resolve(pa));
            } else {
                const data = {
                    plan: {
                        ...plan,
                        returnUrl: apiConfig.mercadoPago.returnUrl,
                        returnUrlBack: apiConfig.mercadoPago.returnUrlBack,
                    },
                    user
                  }
                mpPayment(data,recurrent);
                resolve('purchase');
            }
        });
    }

    return {getPlan, associatePlan, mpPreference};
}