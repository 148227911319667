import React from 'react'
import { Link } from 'react-router-dom'
import { SimpleFooter } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página mostrar error 404
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface NotFoundPage
 */
export const NotFoundPage = () => {
  return (
      <>
        <LandingHeader />
        <div style={{marginTop: "120px"}}></div>
        <NotFoundSection/>
        <SimpleFooter />
      </>
  )
}

const NotFoundSection = () =>{
    const requireImage = require.context('../../assets/images', true);

    return(
        <div className='row justify-content-center'>
            <div className='col-10 col-md-7 col-lg-6 col-xl-5 '>
                <div className='d-flex justify-content-center mb-4'>

                        <img alt={'Robot Not Found'} src={requireImage(`./robot-not-found-page.png`)} /> 
                </div>
                <div className='d-flex justify-content-start justify-content-sm-center NotFound__error-text'>
                    ERROR 404
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className='NotFound__title'>No encontramos lo que buscas</h1>
                </div>
                <div style={{marginBottom: "60px"}}>
                    <p>Parece que la dirección que colocaste no existe o fue eliminada. Puedes <Link className='NotFound__color-green' to="/">Regresar al inicio</Link> o <Link className='NotFound__color-green' to="/contactanos">Contactarnos</Link> para obtener más información.</p>
                </div>
            </div>
        </div>
    )
}
