import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useFetch } from '../../../hooks/useFetch';
import { useAuthToken } from '../../../hooks/useAuthToken';
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para filtrar usuarios en backoffice
 * @param {HTMLElement} children Contenido a mostrar, principalmente inputs para los filtros
 * @param {Event} onSubmit Función submit para enviar formulario de filtro
 * @param {boolean} downloadCSV Valor que activa o desactiva la opción de descargar archivo CSV
 * @param {JSON} data Datos recibidos del endpoint para transformalos y enviarlos al CSV
 * @param {string} filename Nombre asignado al archivo CSV
 * @function Filters
 */
export const Filters = ({children, onSubmit, downloadCSV, data, filename}) =>{
    //Limpia los datos que vienen en los campos dataTreatmentAcceptedBy y termsAcceptedBy
    const cleanData = () => {
        let aux = data;
        for (let i = 0; i < aux.length; i++){
            aux[i].dataTreatmentAcceptedBy = aux[i].dataTreatmentAcceptedBy.replaceAll(',',';');
            aux[i].termsAcceptedBy = aux[i].termsAcceptedBy.replaceAll(',',';');
        }
        return aux;
    }
    let newdata = filename == "listusers.csv" ? cleanData() : data;
    return(
        <div className='FilterAdmin__filters'>
            <div className='row justify-content-center text-start text-md-center mt-2'>
                <form onSubmit={onSubmit}>
                <div className='FilterAdmin__input-group'>
                        {children}
                </div>
                <div className='FilterAdmin__buttons-group'>
                    { downloadCSV &&
                        <CSVLink data={newdata} filename={filename} className='FilterAdmin__downloadcsv-button'><i className='fa-solid fa-download' style={{marginRight: "10px"}}></i> Descargar CSV</CSVLink>
                    }
                    <button className="FilterAdmin__search-button" type="submit">
                        <i className='fa-solid fa-search' style={{marginRight: "15px"}}></i>
                        <span>Buscar</span>
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}